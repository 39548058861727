import {
  useState,
  useEffect,
  ReactNode,
  useContext,
  createContext,
  useMemo,
} from "react";
import dayjs from "dayjs";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import type { ItemInCart } from "../../../../utilities/types";
import { formatDrugDispenseQuantity } from "../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { SortDirectionEnum } from "../../../../components/rxLibrary/selects/SortBySelect";
import { useShoppingRxCartServer } from "./useShoppingRxCartServer";
import { useShoppingState } from "../../../../contexts/ShoppingContext/ShoppingContext";

const ITEM_NAME_SORT_OPTION = {
  value: "itemName",
  label: "Item Name",
  sorter: (item: ItemInCart) => formatDrugDispenseQuantity(item.drug),
};

export const SHOPPING_RX_SORT_OPTIONS = [
  ITEM_NAME_SORT_OPTION,
  // TODO: update after add this field to ItemInCart
  {
    value: "addedAt",
    label: "Date/Time Added",
    // sorter: (item: ItemInCart) => {
    //   return item.addedAt ? dayjs(item.addedAt).unix() : -1;
    // },
  },
  {
    value: "purchaseBy",
    label: "Purchase By Date",
    sorter: (item: ItemInCart) => {
      return item.purchaseBy ? dayjs(item.purchaseBy).unix() : -1;
    },
  },
];

function sortItems(
  items: ItemInCart[],
  sort: typeof SHOPPING_RX_SORT_OPTIONS[number] | undefined,
  sortDirection: SortDirectionEnum
) {
  let newItems = { ...items };

  if (sort) {
    const { value, sorter } = sort;
    const sorters = [sorter ?? value];

    // sort by item name if not already sorted by item name
    if (value !== ITEM_NAME_SORT_OPTION.value) {
      sorters.push(ITEM_NAME_SORT_OPTION.sorter);
    }
    newItems = sortBy(newItems, sorters);
  }

  if (sortDirection === SortDirectionEnum.DESC) {
    newItems.reverse();
  }

  return newItems;
}

const ShoppingRxContext = createContext<
  ReturnType<typeof useShoppingRxContext> | undefined
>(undefined);

function useShoppingRxContext() {
  const {
    inventorySyncDate,
    isInventoryLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    syncInventory,
    syncPrescriptions,
  } = useShoppingRxCartServer();

  const { prescriptionItemsInCart, serverInventoryItemsInCart } =
    useShoppingState();

  const [sort, setSort] = useState(SHOPPING_RX_SORT_OPTIONS[0].value);
  const [sortDirection, setSortDirection] = useState(SortDirectionEnum.DESC);
  const [inventoryItems, setInventoryItems] = useState<ItemInCart[]>([]);
  const [prescriptionsItems, setPrescriptionsItems] = useState<ItemInCart[]>(
    []
  );

  const selectedSort = useMemo(() => {
    return SHOPPING_RX_SORT_OPTIONS.find((o) => o.value === sort);
  }, [sort]);

  useEffect(() => {
    const newInventoryItems = sortItems(
      serverInventoryItemsInCart,
      selectedSort,
      sortDirection
    );
    if (isEqual(inventoryItems, newInventoryItems)) return;

    setInventoryItems(newInventoryItems);
  }, [sortDirection, selectedSort, serverInventoryItemsInCart]);

  useEffect(() => {
    const filteredItems = prescriptionItemsInCart.filter((item) => {
      return item.status === "list";
    });
    const newPrescriptionsItems = sortItems(
      filteredItems,
      selectedSort,
      sortDirection
    );
    if (isEqual(prescriptionsItems, newPrescriptionsItems)) return;

    setPrescriptionsItems(newPrescriptionsItems);
  }, [sortDirection, selectedSort, prescriptionItemsInCart]);

  return {
    sort,
    sortDirection,
    inventoryItems,
    inventorySyncDate,
    prescriptionsItems,
    isInventoryLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    setSort,
    syncInventory,
    setSortDirection,
    syncPrescriptions,
  };
}

export function ShoppingRxContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const context = useShoppingRxContext();
  return (
    <ShoppingRxContext.Provider value={context}>
      {children}
    </ShoppingRxContext.Provider>
  );
}

export function useShoppingRx() {
  const context = useContext(ShoppingRxContext);
  if (!context) {
    throw new Error("useShoppingRx must be used within a ShoppingRxContext");
  }
  return context;
}
