import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import "twin.macro";
import type { Dayjs } from "dayjs";
import type {
  ItemInModal,
  ItemInCart,
} from "../../../../../../utilities/types";
import {
  DATE_WITH_YEAR,
  SHORT_DATE_FORMAT,
  DATE_AND_HOUR_FORMAT,
} from "../../../../../../utilities/dates/dates.constants";
import { formatDateToFormat } from "../../../../../../utilities/dates/formatDateToFormat";
import { Box } from "../../../../../../components/rxLibrary/box";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { IconCalendar } from "../../../../../../components/rxLibrary/icons";
import { ShoppingRxItemHeaderDatePicker } from "./ShoppingRxItemHeaderDatePicker";
import { ShoppingItemHeaderMenu } from "./ShoppingRxItemHeaderMenu";

export function ShoppingRxItemHeader({
  item,
  isInCart,
  inventory,
  hasItemNote,
  removeItem,
  openItemNotePopover,
  updateItemPurchaseBy,
}:
  | {
      removeItem: () => void;
    } & (
      | {
          item: ItemInCart;
          isInCart: true;
          hasItemNote: boolean;
          inventory?: boolean;
          openItemNotePopover: () => void;
          updateItemPurchaseBy: (value?: Dayjs) => void;
        }
      | {
          item: ItemInModal;
          isInCart?: false;
          inventory?: boolean;
          hasItemNote?: boolean;
          openItemNotePopover?: undefined;
          updateItemPurchaseBy?: undefined;
        }
    )) {
  const { patient, rxNumber, fillDate } = item;
  const purchaseBy = isInCart ? item.purchaseBy : undefined;
  const patientShortName = patient?.shortName;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  // TODO: validate with JT this field since we are using fillDate two times in the same component
  const addedDateStr = useMemo(() => {
    return formatDateToFormat(fillDate, DATE_AND_HOUR_FORMAT);
  }, [fillDate]);

  const purchaseByDate = useMemo(() => {
    if (purchaseBy) return dayjs(purchaseBy);
  }, [purchaseBy]);

  const purchaseByStr = useMemo(() => {
    return formatDateToFormat(purchaseByDate, SHORT_DATE_FORMAT);
  }, [purchaseByDate]);

  const fillDateStr = useMemo(() => {
    return formatDateToFormat(fillDate, DATE_WITH_YEAR);
  }, [fillDate]);

  const closeByByDateDatePicker = useCallback(() => {
    setIsDatePickerOpen(false);
  }, []);

  const _openByByDateDatePicker = useCallback(() => {
    setIsDatePickerOpen(true);
  }, []);
  const openByByDateDatePicker = updateItemPurchaseBy
    ? _openByByDateDatePicker
    : undefined;

  return (
    <div tw="flex justify-between items-center">
      <div tw="h-[30px] mb-[1px]">
        {!inventory && (
          <Box
            bgColor="white-1"
            tw="flex items-center h-full rounded-t space-x-[3px] shadow-[0_0_1px_1px_#00000014] w-[245px] px-[10px]"
          >
            <img src="/assets/components/rx.svg" alt="rx" />

            <p>
              <Text weight="bold">
                {rxNumber}
                {!!patientShortName && `, ${patientShortName}`}
              </Text>
              {!!fillDateStr && (
                <Text>
                  {" | "}Fill date: <Text weight="bold">{fillDateStr}</Text>
                </Text>
              )}
            </p>
          </Box>
        )}
      </div>

      <div tw="flex items-center space-x-[5px]">
        {!!purchaseByStr && (
          <Button
            icon={<IconCalendar />}
            variant="text-1"
            onClick={openByByDateDatePicker}
          >
            Buy by {purchaseByStr}
          </Button>
        )}

        {!!isInCart && (
          <ShoppingRxItemHeaderDatePicker
            date={purchaseByDate}
            open={isDatePickerOpen}
            disableToday={!purchaseByDate}
            close={closeByByDateDatePicker}
            onChange={updateItemPurchaseBy}
            onOpenChange={setIsDatePickerOpen}
          />
        )}

        {!!addedDateStr && <Text block>Added: {addedDateStr}</Text>}

        <ShoppingItemHeaderMenu
          hasItemNote={hasItemNote}
          removeItem={removeItem}
          openItemNotePopover={openItemNotePopover}
          openByByDateDatePicker={openByByDateDatePicker}
        />
      </div>
    </div>
  );
}
