import React, { useCallback, useMemo, useState } from "react";
import { css } from "twin.macro";
import Modal from "@mui/material/Modal";
import { Button } from "../../../rxLibrary/buttons";
import { Box } from "../../../rxLibrary/box";
import { Title } from "../../../rxLibrary/typography";
import { WideContainer } from "../../../containers/WideContainer";
import { getPharmacyRefillItems } from "../../../../services/pharmacies";
import { useRequest } from "../../../../services/request/useRequest";
import { useRefillOpportunities } from "../../RefillOpportunitiesContext";
import { AcquisitionCostSkeleton } from "./AcquisitionCostSkeleton";
import { AcquisitionCostSupplier } from "./AcquisitionCostSupplier";
import { AcquisitionCostError } from "./AcquisitionCostError";

export const SHADOW_TOP_CSS = css`
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.16);
`;

export function AcquisitionCostSettings({ close }: { close: () => void }) {
  const [shouldRefetchOnClose, setShouldRefetchOnClose] = useState(false);
  const { pharmacyId, refillMappings, refetch } = useRefillOpportunities();

  const config = useMemo(() => ({ pharmacyId }), [pharmacyId]);
  const { data, isLoading, error } = useRequest({
    config,
    dataKey: "refillItemsBySupplierId",
    request: getPharmacyRefillItems,
  });

  const enableRefetchOnClose = useCallback(() => {
    setShouldRefetchOnClose(true);
  }, []);

  const handleClose = useCallback(() => {
    if (shouldRefetchOnClose) {
      refetch();
      setShouldRefetchOnClose(false);
    }
    close();
  }, [close, refetch, shouldRefetchOnClose]);

  return (
    <Modal open onClose={handleClose}>
      <WideContainer tw="mt-8 mx-auto">
        <Box bgColor="grey-4" tw="p-20">
          <Title tw="mb-12" block>
            Acquisition Cost Setup
          </Title>

          <div tw="h-[50dvh] min-h-[350px] overflow-y-auto">
            {isLoading && <AcquisitionCostSkeleton />}

            {error && <AcquisitionCostError />}

            {!!data &&
              refillMappings?.map((v) => (
                <AcquisitionCostSupplier
                  key={v.supplierId}
                  pharmacyId={pharmacyId}
                  supplierId={v.supplierId}
                  refillItems={data[v.supplierId]}
                  enableRefetchOnClose={enableRefetchOnClose}
                />
              ))}
          </div>
        </Box>

        <Box
          bgColor="white-1"
          tw="flex justify-end px-20 py-8"
          css={SHADOW_TOP_CSS}
        >
          <Button variant="text-1" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </WideContainer>
    </Modal>
  );
}
