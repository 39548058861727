import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import { Button } from "../../rxLibrary/buttons";
import { IconDollar } from "../../rxLibrary/icons";

export function RefillOpportunitiesPaymentsReportLink() {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { reports } = currentBuyingPharmacy ?? {};

  const hasPaymentsReport = useMemo(() => {
    return !!reports?.some((report) => report.id === 41);
  }, [reports]);

  if (!hasPaymentsReport) return null;
  return (
    <Button variant="text-1" icon={<IconDollar />}>
      <Link to="/performance?report=41">Payments Earned</Link>
    </Button>
  );
}
