import React, { useMemo } from "react";
import { styled } from "twin.macro";
import { ItemInCart } from "../../../../../utilities/types";
import { Text } from "../../../../../components/rxLibrary/typography";
import { GREY_2 } from "../../../../../components/rxLibrary/colors";
import { useBuyingPharmacy } from "../../../../../contexts/BuyingPharmacyContext";
import { PmsEnum } from "../../../../../utilities/pms";
import { formatBohEoh } from "../../../utils/formatBohEoh";
import { formatDispenseQuantity } from "../../../utils/formatDispenseQuantity";

const TextWrapper = styled.div`
  padding: 0 12px;
  border-bottom: 1px solid ${GREY_2};
`;

export function ShoppingListColumnDispensedQuantity({
  prescription,
  hideDispensedQuantity,
}: {
  prescription: ItemInCart;
  hideDispensedQuantity?: boolean;
}) {
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { pms, keepsInventory: pharmacyKeepsInventory } =
    currentBuyingPharmacy ?? {};
  const isLiberty = pms === PmsEnum.Liberty;

  const { eoh, boh } = prescription;
  
  const eohFormatted = useMemo(() => formatBohEoh(eoh), [eoh]);
  const bohFormatted = useMemo(() => formatBohEoh(boh), [boh]);

  const dispensedQuantity = useMemo(() => {
    return formatDispenseQuantity(prescription);
  }, [prescription]);

  if (!pharmacyKeepsInventory) {
    if (hideDispensedQuantity) return null;
    return <Text weight="bold">{dispensedQuantity}</Text>;
  }

  return (
    <div tw="flex flex-col items-center">
      {!hideDispensedQuantity && (
        <TextWrapper>
          <Text weight="bold">{dispensedQuantity}</Text>
        </TextWrapper>
      )}

      <div>
        <Text color="grey-2">BOH: </Text>
        <Text weight="bold">{bohFormatted} </Text>

        {!isLiberty && (
          <>
            <Text color="grey-2">EOH </Text>
            <Text weight="bold">{eohFormatted}</Text>
          </>
        )}
      </div>
    </div>
  );
}
