const audioContext = new AudioContext();

// Reference: https://ourcodeworld.com/articles/read/1627/how-to-easily-generate-a-beep-notification-sound-with-javascript
export async function beep(config?: {
  volume?: number;
  duration?: number;
  frequency?: number;
  propagateError?: boolean;
}) {
  const {
    duration = 50,
    frequency = 420,
    volume = 100,
    propagateError = false,
  } = config ?? {};

  return new Promise((resolve, reject) => {
    try {
      const oscillatorNode = audioContext.createOscillator();
      const gainNode = audioContext.createGain();
      oscillatorNode.connect(gainNode);

      // Set the oscillator frequency in hertz
      oscillatorNode.frequency.value = frequency;

      // Set the type of oscillator
      oscillatorNode.type = "square";
      gainNode.connect(audioContext.destination);

      // Set the gain to the volume
      gainNode.gain.value = volume * 0.01;

      // Start audio with the desired duration
      oscillatorNode.start(audioContext.currentTime);
      oscillatorNode.stop(audioContext.currentTime + duration * 0.001);

      // Resolve the promise when the sound is finished
      oscillatorNode.onended = () => {
        resolve(undefined);
      };
    } catch (error) {
      console.error("Failed to play beep sound");
      if (propagateError) reject(error);
      else resolve(undefined);
    }
  });
}
