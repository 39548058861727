import { useCallback, useMemo } from "react";
import "twin.macro";
import type { Dayjs } from "dayjs";
import type { ItemInCart } from "../../../../utilities/types";
import { usePrescriptionNote } from "../../../../components/PrescriptionNote/usePrescriptionNote";
import { useShoppingUpdater } from "../../../../contexts/ShoppingContext/ShoppingContext";
import { ShoppingRxPrescriptionItem } from "./components/ShoppingRxPrescriptionItem";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";

export function ShoppingRxCartPrescriptionItem({ item }: { item: ItemInCart }) {
  const id = useMemo(() => getPrescriptionId(item), [item]);

  const {
    updatePrescriptionNote,
    updatePrescriptionStatus,
    updatePrescriptionPackSize,
    updatePrescriptionPurchaseBy,
    updatePrescriptionManufacturer,
  } = useShoppingUpdater();

  const {
    hasPrescriptionNote: hasItemNote,
    prescriptionNotePopover: itemNotePopover,
    openPrescriptionNotePopover: openItemNotePopover,
  } = usePrescriptionNote({
    prescription: item,
    updatePrescriptionNote,
  });

  const updateItemPackSize = useCallback(
    (value: boolean) => {
      updatePrescriptionPackSize(item, value);
    },
    [item, updatePrescriptionPackSize]
  );

  const updateItemManufacturer = useCallback(
    (value: boolean) => {
      updatePrescriptionManufacturer(item, value);
    },
    [item, updatePrescriptionManufacturer]
  );

  const removeItem = useCallback(() => {
    updatePrescriptionStatus(item, "add");
  }, [item, updatePrescriptionStatus]);

  const updateItemPurchaseBy = useCallback(
    (value?: Dayjs) => {
      updatePrescriptionPurchaseBy(id, value);
    },
    [id, updatePrescriptionPurchaseBy]
  );

  return (
    <ShoppingRxPrescriptionItem
      isInCart
      item={item}
      hasItemNote={hasItemNote}
      itemNotePopover={itemNotePopover}
      removeItem={removeItem}
      updateItemPackSize={updateItemPackSize}
      openItemNotePopover={openItemNotePopover}
      updateItemPurchaseBy={updateItemPurchaseBy}
      updateItemManufacturer={updateItemManufacturer}
    />
  );
}
