import React, { useContext, useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { Svg } from "../../../../../components/Svg";
import {
  ComparisonSummaryType,
  SavingsTableDataType,
  SVGType,
} from "../../../../../utilities/types";
import { SavingsModal } from "./SavingsModal/SavingsModal";
import PharmaciesContext from "../../../../../contexts/PharmaciesContext";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../../../contexts/ShoppingContext/ShoppingContext";
import { TableHeaderDescription } from "../../tables/table/tableHeader/TableHeaderDescription";
import { TableHeaderCurrency } from "../../tables/table/tableHeader/TableHeaderCurrency";
import { Text } from "../../../../../components/rxLibrary/typography";
import { currencyRoundedToNearestDollar } from "../../../../../utilities/numbers/currency";

const MIN_SAVINGS_TO_DISPLAY = 40;

export function Savings() {
  const { setHasPricesChanged } = useShoppingUpdater();
  const { optimizeCartResponse, hasPricesChanged } = useShoppingState();
  const { supplierFullNameById } = useContext(PharmaciesContext);

  const [savingsList, setSavingsList] = useState<SavingsTableDataType>([]);
  const [initialComparisonSummary, setInitialComparisonSummary] =
    useState<ComparisonSummaryType>();
  const [isSavingsModalOpen, setSavingsModalOpen] = useState(false);

  function openModal() {
    setSavingsModalOpen(true);
  }

  function closeModal() {
    setSavingsModalOpen(false);
  }

  useEffect(() => {
    const { supplierOrders, comparisonSummary } =
      optimizeCartResponse.data?.selections ?? {};
    if (!supplierOrders || !comparisonSummary) {
      return;
    }

    const newSavingsList = supplierOrders
      .reduce<SavingsTableDataType>((acc, order) => {
        const formattedItems = order.items.reduce<SavingsTableDataType>(
          (accItems, item) => {
            if (
              item.comparison?.savings &&
              item.comparison.savings > 0 &&
              !item.comparison.isNonComparable
            ) {
              const formattedItem = {
                itemDesc: { drug: item.referenceData.drugInfo, numPackages: item.numPackages },
                originalItem: {
                  comparison: item.comparison,
                  supplierName: item.comparison.originalSupplierId ? supplierFullNameById[item.comparison.originalSupplierId] : '',
                },
                suggestedItem: {
                  ndc: item.referenceData.drugInfo.ndc,
                  price: item.referenceData.catalogInfo.price * item.numPackages,
                  supplierName: item.referenceData.catalogInfo.supplierDisplayName,
                },
                savings: item.comparison?.savings,
                savingsReason: item?.comparison.savingsReason,
              };
              accItems.push(formattedItem);
            }

            return accItems;
          },
          []
        );

        return [...acc, ...formattedItems];
      }, [])
      .sort((a, b) => b.savings - a.savings);

    setHasPricesChanged(false);
    setSavingsList(newSavingsList);
    setInitialComparisonSummary(comparisonSummary);
  }, []);

  if (!initialComparisonSummary) {
    return null;
  }

  const totalSaving = initialComparisonSummary.savingsGrandTotal || 0;
  const shouldDisplaySavings =
    totalSaving >= MIN_SAVINGS_TO_DISPLAY && savingsList.length > 0;
  if (!shouldDisplaySavings) {
    return null;
  }

  const total = currencyRoundedToNearestDollar(totalSaving, true);
  const firstSavingsItemPrice = currencyRoundedToNearestDollar(
    savingsList[0]?.savings || 0
  );
  const firstSavingsItemName = savingsList[0]?.itemDesc?.drug?.name || "";
  const firstSavingsItemStrength =
    savingsList[0]?.itemDesc?.drug?.strength || "";
  const firstSavingsItemStrengthUnit =
    savingsList[0]?.itemDesc?.drug?.strengthUnit || "";
  const remainingItemsLength = savingsList.length - 1;
  const displayedSavingsTotal =
    initialComparisonSummary.displayedSavingsTotal || 0;
  const minShipManagementAndShippingTotal =
    initialComparisonSummary.minShipManagementAndShippingTotal || 0;

  const savingsItemData =
    `${firstSavingsItemPrice} on ${firstSavingsItemName} ` +
    `${firstSavingsItemStrength} ${firstSavingsItemStrengthUnit}`;

  return (
    <>
      <div className="savings flex items-center supplier-block__right">
        <Svg icon={SVGType.Savings} width={52} height={48} />
        <div tw="space-y-[6px] ml-2 mr-4">
          <TableHeaderCurrency value={total} />
          <TableHeaderDescription>Savings</TableHeaderDescription>
        </div>

        <Divider orientation="vertical" sx={{ height: "55%" }} />

        <div className="ml-14">
          <Text color="grey-1" block>
            <p>Your Savings:</p>
            {remainingItemsLength === 0 ? (
              <a
                href="javascript:;"
                onClick={openModal}
                className="underline hover-underline"
              >
                {savingsItemData}
              </a>
            ) : (
              <p className="no-wrap">{savingsItemData}</p>
            )}
            {remainingItemsLength > 0 && (
              <a
                href="javascript:;"
                onClick={openModal}
                className="underline hover-underline"
              >
                + {remainingItemsLength} other items
              </a>
            )}
          </Text>
        </div>
      </div>

      {isSavingsModalOpen && (
        <SavingsModal
          onClose={closeModal}
          savingsTableData={savingsList}
          savingsGrandTotal={totalSaving}
          displayedSavingsTotal={displayedSavingsTotal}
          minShipManagementAndShippingTotal={minShipManagementAndShippingTotal}
          hasPricesChanged={hasPricesChanged}
        />
      )}
    </>
  );
}
