import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  currencyFormatter,
  numberFormatter,
} from "../../utils/performanceReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    field: "pharmacyName",
    headerName: "Pharmacy",
    pinned: "left",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "programName",
    headerName: "Program Name",
    sort: "asc",
    sortIndex: 1,
    flex: 1,
    minWidth: 225,
  },
  {
    field: "monthPretty",
    headerName: "Time Period",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "month",
    headerName: "Month",
    hide: true,
    sort: "desc",
    sortIndex: 0,
    flex: 1,
    minWidth: 125
  },
  {
    field: "units",
    headerName: "Outreaches",
    valueFormatter: ({ value }) => numberFormatter(value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "serviceFee",
    headerName: "Fee",
    valueFormatter: ({ value }) => currencyFormatter(value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "totalEarned",
    headerName: "Total Earned",
    valueFormatter: ({ value }) => currencyFormatter(value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "note",
    headerName: "Note",
    flex: 1,
    minWidth: 125,
  },
];
