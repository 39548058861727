import type { DrugInfo } from "../../../../../../utilities/types";
import { Tag } from "../../../../../../components/rxLibrary/tags";

/*
isRx | isNarrowTherapeuticIndex | isBrand	| Result               | Result Small
-----|--------------------------|---------|----------------------|-------------------------
F	   | F	                      | F       | null                 |  null
T	   | F	                      | F       | null                 |  null
F	   | T	                      | F       | Narrow Therapeutic   |  NARROW THRP
T	   | T	                      | F       | Narrow Therapeutic   |  NARROW THRP
F	   | F	                      | T       | Brand OTC            |  BRAND OTC
T	   | F	                      | T       | Brand RX             |  BRAND RX
F	   | T	                      | T       | Brand & Narrow Thrp  |  BRAND & NTI
T	   | T	                      | T       | Brand & Narrow Thrp  |  BRAND & NTI
*/
export function PrescriptionTag({
  isInventory,
  prescription,
  small,
}: {
  isInventory: boolean;
  prescription: {
    drug: DrugInfo;
    dawCode: number | null;
    noManufacturerPreference?: boolean;
  };
  small?: boolean;
}) {
  const { drug, noManufacturerPreference, dawCode } = prescription;

  if (dawCode && !isInventory) {
    return <Tag>DAW {dawCode}</Tag>;
  }

  if (noManufacturerPreference) {
    return null;
  }

  const { isNarrowTherapeuticIndex, isBrand, isRx } = drug;
  if (isBrand) {
    if (isNarrowTherapeuticIndex) {
      return <Tag>{small ? "BRAND & NTI" : "Brand & Narrow Thrp"}</Tag>;
    }

    if (isRx) {
      return <Tag>{small ? "BRAND RX" : "Brand Rx"}</Tag>;
    }

    return <Tag>{small ? "BRAND OTC" : "Brand OTC"}</Tag>;
  }

  if (isNarrowTherapeuticIndex) {
    return <Tag>{small ? "NARROW THRP" : "Narrow Therapeutic"}</Tag>;
  }

  return null;
}
