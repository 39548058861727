import React from "react";
import { Global, css } from "@emotion/react";
import { WideContainer } from "../../components/containers/WideContainer";
import { Box } from "../../components/rxLibrary/box";

const PERFORMANCE_REPORT_CLASS_NAME = "rx-report";

const GLOBAL_CSS = css`
  .${PERFORMANCE_REPORT_CLASS_NAME} {
    .chart-tooltip {
      .ag-chart-tooltip-title {
        font-family: "Archivo Narrow Variable", sans-serif;
        font-size: 16px;
      }
      .ag-chart-tooltip-content {
        font-family: "Archivo Narrow Variable", sans-serif;
        font-size: 14px;
      }
    }

    .chart-overlays {
      font-family: "Archivo Narrow Variable", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`;

export function ReportWrapper({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Box bgColor="grey-4">
        <WideContainer
          tw="h-[calc(100dvh - 236px)]"
          className={PERFORMANCE_REPORT_CLASS_NAME}
        >
          {children}
        </WideContainer>
      </Box>
      <Global styles={GLOBAL_CSS} />
    </>
  );
}
