import React, { useMemo } from "react";
import type { ItemInModal, ItemInCart } from "../../../../../../utilities/types";
import { useBuyingPharmacy } from "../../../../../../contexts/BuyingPharmacyContext";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { PmsEnum } from "../../../../../../utilities/pms";
import { formatBohEoh } from "../../../../utils/formatBohEoh";

export function ShoppingRxItemPanelEohBoh({ item }: { item: ItemInCart | ItemInModal }) {
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { pms, keepsInventory } = currentBuyingPharmacy ?? {};
  const isLiberty = pms === PmsEnum.Liberty;
  const eoh = "eoh" in item ? item.eoh : undefined;
  const boh = "boh" in item ? item.boh : undefined;

  const eohFormatted = useMemo(() => {
    if (eoh) return formatBohEoh(eoh);
  }, [eoh]);

  const bohFormatted = useMemo(() => {
    if (boh) return formatBohEoh(boh);
  }, [boh]);

  if (!currentBuyingPharmacy || !keepsInventory) return null;
  return (
    <>
      {" | "}
      <Text color="grey-2">BOH: </Text>
      {bohFormatted}

      {!isLiberty && (
        <>
          <Text color="grey-2"> EOH: </Text> {eohFormatted}
        </>
      )}
    </>
  );
}
