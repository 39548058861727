import type { Dayjs } from "dayjs";
import React, { useMemo } from "react";
import { getModeUrlReport } from "../../services/report";
import { useRequest } from "../../services/request/useRequest";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { PerformanceReportError } from "./PerformanceReportError";
import { ReportWrapper } from "./ReportWrapper";

export function PerformanceReportModal({
  reportId,
  endDate,
  startDate,
  iframeProps,
  selectedPharmacies,
}: {
  reportId: number;
  endDate: Dayjs;
  startDate: Dayjs;
  iframeProps?: Record<string, any>;
  selectedPharmacies: number[];
}) {
  const config = useMemo(() => {
    return {
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
      reportId,
      pharmacyId: selectedPharmacies,
    };
  }, [reportId, startDate, endDate, selectedPharmacies]);

  const {
    data: reportUrl,
    error,
    isLoading,
  } = useRequest({ config, dataKey: "signedUrl", request: getModeUrlReport });

  return (
    <>
      {!!reportUrl && (
        <ReportWrapper>
          <iframe {...iframeProps} src={reportUrl} tw="w-full h-full" />
        </ReportWrapper>
      )}
      {!!error && <PerformanceReportError />}
      {isLoading && <FullPageLoader text="Loading Analytics" />}
    </>
  );
}
