import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import "twin.macro";
import { Form, Tooltip, InputRef } from "antd";
import type { ItemInModal } from "../../../utilities/types";
import { beep } from "../../../utilities/sound/beep";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { useRequestClient } from "../../../services/request/requestClient";
import { getRxes } from "../../../services/prescriptions";
import { Button } from "../../../components/rxLibrary/buttons";
import { Text } from "../../../components/rxLibrary/typography";
import {
  IconBell,
  IconCheck,
  IconMagnify,
} from "../../../components/rxLibrary/icons";
import { AutoCompleteInputSearch } from "../../../components/antd/AutoComplete";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { Modal } from "../../../components/rxLibrary/modals";
import { getInitialManufacturerFromDrugInfo } from "../utils/getInitialManufacturerFromDrugInfo";
import { ShoppingRxModalPrescriptionItem } from "./shoppingRxItems/ShoppingRxModalPrescriptionItem";
import { useShoppingRx } from "./useShoppingRx/useShoppingRx";

type ItemsInModalByRx = Record<string, ItemInModal>;

export enum TooltipErrorEnum {
  NOT_FOUND = "NOT_FOUND",
  DUPLICATED_IN_LIST = "DUPLICATED_IN_LIST",
  DUPLICATED_IN_CART = "DUPLICATED_IN_CART",
  UNEXPECTED = "UNEXPECTED",
}

function TooltipError({
  error,
  close,
}: {
  error: TooltipErrorEnum;
  close: () => void;
}) {
  function renderMessage() {
    if (error === TooltipErrorEnum.NOT_FOUND) {
      return (
        <Text>
          Oops, we can’t find this Rx.
          <br />
          Try entering it again please,
          <br />
          Make sure there are no mistakes
        </Text>
      );
    }

    if (error === TooltipErrorEnum.DUPLICATED_IN_LIST) {
      return <Text>This Rx is already on the list.</Text>;
    }

    if (error === TooltipErrorEnum.DUPLICATED_IN_CART) {
      return <Text>This Rx is already in the cart.</Text>;
    }

    return <Text>Unexpected error</Text>;
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" || event.key === "Escape") close();
    }
  }, [close]);

  return (
    <div tw="flex flex-col items-center text-center w-[220px] p-[20px] pb-[10px] space-y-[7px]">
      <IconBell color="red-1" />
      {renderMessage()}
      <Button variant="text-1" onClick={close}>
        OK
      </Button>
    </div>
  );
}

export function ShoppingRxPrescriptionModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const requestClient = useRequestClient();
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { prescriptionsItems } = useShoppingRx();

  const [form] = Form.useForm();
  const searchRef = useRef<InputRef>(null);
  const [itemsByRx, setItemsByRx] = useState<ItemsInModalByRx>({});
  const [loading, setLoading] = useState(false);
  const [tooltipError, setTooltipError] = useState<TooltipErrorEnum>();
  const items = useMemo(() => Object.values(itemsByRx), [itemsByRx]);
  const itemsCount = items.length;
  const hasTooltipError = !!tooltipError;

  const rxInCartSet = useMemo(() => {
    const items = new Set<string>();
    prescriptionsItems.forEach((item) => {
      if (item.status === "list" && item.rxNumber) items.add(item.rxNumber);
    });
    return items;
  }, [prescriptionsItems]);

  const closeTooltipError = useCallback(() => {
    setTooltipError(undefined);
    form.setFieldValue("search", "");
  }, [form]);

  const handleOpenChangeTooltipError = useCallback(
    (open: boolean) => !open && closeTooltipError(),
    [closeTooltipError]
  );

  const removeModalItem = useCallback(
    (rxNumber: string) => {
      const { [rxNumber]: _, ...newItemsByRx } = itemsByRx;
      setItemsByRx(newItemsByRx);
    },
    [itemsByRx]
  );

  const updateModalItem = useCallback(
    (item: ItemInModal) => {
      const newItemsByRx = { ...itemsByRx, [item.rxNumber]: item };
      setItemsByRx(newItemsByRx);
    },
    [itemsByRx]
  );

  const handleAddItemsToCart = useCallback(() => {
    // TODO: add items to cart
  }, []);

  const handleAddItemsToModal = useCallback(
    async (values: { search?: string }) => {
      const rx = values.search;
      if (!pharmacyId || !rx) return;

      setLoading(true);
      try {
        if (rxInCartSet.has(rx)) {
          throw new Error(TooltipErrorEnum.DUPLICATED_IN_CART);
        }

        const response = await getRxes(requestClient, { rx, pharmacyId });

        const { error, prescriptions } = response?.data?.data ?? {};
        if (error || !prescriptions?.length) {
          const errorMessage =
            error?.message === "Rx not found"
              ? TooltipErrorEnum.NOT_FOUND
              : TooltipErrorEnum.UNEXPECTED;
          throw new Error(errorMessage);
        }

        const newItemsByRx = structuredClone(itemsByRx);
        prescriptions.forEach((prescription) => {
          const { drug, rxNumber } = prescription;
          if (itemsByRx[rxNumber])
            throw new Error(TooltipErrorEnum.DUPLICATED_IN_LIST);

          const item: ItemInModal = {
            ...prescription,
            eoh: 0,
            boh: 0,
            status: "list",
            packSize: true,
            manufactutrer: getInitialManufacturerFromDrugInfo(drug),
          };
          newItemsByRx[rxNumber] = item;
        });

        form.setFieldValue("search", "");
        setItemsByRx(newItemsByRx);
      } catch (error: any) {
        await beep();
        setTooltipError(error?.message ?? TooltipErrorEnum.UNEXPECTED);
      } finally {
        setLoading(false);
      }
    },
    [form, itemsByRx, pharmacyId, rxInCartSet, requestClient]
  );

  useEffect(() => {
    if (!tooltipError) searchRef.current?.focus();
  }, [tooltipError]);

  return (
    <Modal onCancel={onClose} open>
      <div tw="flex flex-col h-full pt-[60px]">
        <div tw="flex items-center justify-between px-[48px] mb-[25px]">
          <div tw="flex items-center space-x-[8px]">
            <img
              tw="w-[48px]"
              src="/assets/shopping/prescription-icon.png"
              alt="shopping list"
            />
            <Text size={20}>
              Prescriptions to Buy {!!itemsCount && `(${itemsCount})`}
            </Text>
          </div>

          <div tw="flex items-center space-x-[6px]">
            <Text size="medium" color="grey-1">
              Rx# to add
            </Text>

            <Tooltip
              color="white-1"
              trigger="contextMenu"
              placement="bottom"
              open={hasTooltipError}
              onOpenChange={handleOpenChangeTooltipError}
              title={() =>
                !!tooltipError && (
                  <TooltipError
                    error={tooltipError}
                    close={closeTooltipError}
                  />
                )
              }
              destroyTooltipOnHide
            >
              <Form
                form={form}
                initialValues={{ search: "" }}
                onFinish={handleAddItemsToModal}
              >
                <Form.Item name="search" noStyle>
                  <AutoCompleteInputSearch
                    ref={searchRef}
                    tw="w-[188px]"
                    type="text"
                    size="large"
                    placeholder="Rx#"
                    enterButton={<IconMagnify />}
                    disabled={!pharmacyId || hasTooltipError}
                    allowClear
                  />
                </Form.Item>
              </Form>
            </Tooltip>
          </div>
        </div>

        <div tw="flex-1 overflow-y-scroll px-[48px] space-y-[10px] pb-[25px]">
          {items.map((item) => (
            <ShoppingRxModalPrescriptionItem
              key={item.rxNumber}
              item={item}
              updateModalItem={updateModalItem}
              removeModalItem={removeModalItem}
            />
          ))}
        </div>

        <div tw="flex items-center justify-end space-x-[19px] px-[48px] h-[80px] shadow-[0_-2px_8px_0_#00000029]">
          <Button variant="text-1" onClick={onClose}>
            Cancel
          </Button>
          <Button
            icon={<IconCheck />}
            disabled={!itemsCount}
            onClick={handleAddItemsToCart}
          >
            Add ({itemsCount}) to list
          </Button>
        </div>
      </div>

      {loading && <FullPageLoader />}
    </Modal>
  );
}
