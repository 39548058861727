export function formatBohEoh(value: number) {
  let newValue = value;
  if (newValue < -100) {
    newValue = Math.round(newValue);
  }
  if (newValue <= -10000) {
    newValue = -9999;
  }
  if (Math.abs(newValue) >= 1000) {
    newValue = Math.ceil(newValue);
  }

  return newValue;
}
