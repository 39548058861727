import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import type { Dayjs } from "dayjs";
import type {
  BlobIntervalsProps,
  Prescription,
} from "../../../../utilities/types";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { useAuth0 } from "../../../../contexts/AuthenticationContext";
import { useServerUpdateNotifications } from "../../../../contexts/ServerUpdateNotificationsContext";
import { useShoppingCartServerUpdater } from "../../../../contexts/ShoppingCartServerContext";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../../contexts/ShoppingContext/ShoppingContext";
import { useStorageUpdater } from "../../../../contexts/StorageContext";
import { getPrescriptions } from "../../../../services/prescriptions";
import { mergePmsDataWithCartData } from "../../ShoppingList/mergePmsDataWithCartData";
import dayjs from "dayjs";

const BLOB_PUSH_TIME = 2000;
const INTERVAL_PATH = "/shopping-list-add-rx";

// TODO: check if unify logic with ShoppingList.tsx
export function useShoppingRxCartServer() {
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();

  const { getAccessTokenSilently } = useAuth0();
  const { activePrescriptionsPulledEvent } = useServerUpdateNotifications();
  const location = useLocation();
  const { loadCartData, pushBlob, setUseBlob } = useShoppingCartServerUpdater();
  const { serverItemPurchaseDetails } = useShoppingState();
  const {
    removeFromCart,
    setPrescriptionItemsInCart,
    setPrescriptionItemsActive,
  } = useShoppingUpdater();
  const { setWaitButtonMode, addInterval, resetInterval } = useStorageUpdater();

  const [isInventoryLoading, setIsInventoryLoading] = useState(true);
  const [isPrescriptionsLoading, setIsPrescriptionsLoading] = useState(true);
  const [inventorySyncDate, setInventorySyncDate] = useState<Dayjs>();
  const [prescriptionsSyncDate, setPrescriptionsSyncDate] = useState<Dayjs>();

  const [blobRetrieved, setBlobRetrieved] = useState(false);
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);

  const getInventoryData = useCallback(async () => {
    setIsInventoryLoading(true);
    const loadedCartData = await loadCartData();
    if (loadedCartData?.inventory) {
      setBlobRetrieved(true);
    }
    setIsInventoryLoading(false);
    setInventorySyncDate(dayjs());
  }, [loadCartData]);

  const getPmsData = useCallback(
    async (useCache = false) => {
      if (!pharmacyId) return;

      setIsPrescriptionsLoading(true);

      const q = new URLSearchParams(location.search).get("q") as string;
      const token = await getAccessTokenSilently();
      const { data } = await getPrescriptions(
        pharmacyId,
        token,
        q === "m",
        useCache
      );

      // If we have data
      if (data?.prescriptions) {
        setWaitButtonMode(true);
        setTimeout(() => {
          setWaitButtonMode(false);
        }, 0);
        setPrescriptionsSyncDate(dayjs());
        setPrescriptions(data.prescriptions);
        setPrescriptionItemsActive(true);
      }
      setIsPrescriptionsLoading(false);
    },
    [
      pharmacyId,
      getPrescriptions,
      setWaitButtonMode,
      getAccessTokenSilently,
      setPrescriptionItemsActive,
    ]
  );

  useEffect(() => {
    let blobInterval: NodeJS.Timeout | null = null;

    if (blobRetrieved) {
      // start pushing
      blobInterval = setInterval(() => {
        const path = location.pathname;
        if (path === INTERVAL_PATH) {
          pushBlob({ second: false, force: false });
        }
      }, BLOB_PUSH_TIME);

      const intervalObj: BlobIntervalsProps = {
        id: pharmacyId,
        intervalId: blobInterval,
      };
      addInterval(intervalObj);
    }
    return () => {
      if (blobInterval) {
        clearInterval(blobInterval);
      }
    };
  }, [blobRetrieved]);

  useEffect(() => {
    if (activePrescriptionsPulledEvent > 0) {
      getPmsData(true);
    }
  }, [activePrescriptionsPulledEvent, getPmsData]);

  useEffect(() => {
    // adjust pms data based on Cart data
    if (prescriptions.length > 0) {
      serverItemPurchaseDetails.forEach((item) => {
        // TODO eventually remove this once sufficient time has passed to clear out old data
        if (item.id.indexOf("_") >= 0) {
          removeFromCart(item.id);
          return;
        }
        if (item.id.indexOf("c") >= 0) {
          return;
        }
        const match = prescriptions.find((p) => p.rxNumber === item.id);
        if (!match) {
          removeFromCart(item.id);
        }
      });

      const mergedPrescriptions = mergePmsDataWithCartData(
        prescriptions,
        serverItemPurchaseDetails
      );
      setPrescriptionItemsInCart(mergedPrescriptions);
    } else {
      setPrescriptionItemsInCart([]);
    }
  }, [
    prescriptions,
    serverItemPurchaseDetails,
    removeFromCart,
    setPrescriptionItemsInCart,
  ]);

  useEffect(() => {
    setPrescriptionItemsInCart([]);

    setIsPrescriptionsLoading(false);
    setIsInventoryLoading(true);

    setTimeout(() => {
      getInventoryData();
      getPmsData();
    }, 0);

    // reset blob intervals
    resetInterval();
  }, [pharmacyId]);

  useEffect(() => {
    setUseBlob(true);
  }, []);

  return {
    inventorySyncDate,
    isInventoryLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    syncInventory: getInventoryData,
    syncPrescriptions: getPmsData,
  };
}
