import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import "twin.macro";
import dayjs, { Dayjs } from "dayjs";
import isString from "lodash/isString";
import intersection from "lodash/intersection";
import type { Pharmacy } from "../../utilities/types";
import { getQueryParams } from "../../utilities/queryParams/getQueryParams";
import PharmaciesContext from "../../contexts/PharmaciesContext";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { getLocalStorageValue } from "../../utilities/localStorage/getLocalStorageValue";
import { setLocalStorageValue } from "../../utilities/localStorage/setLocalStorageValue";
import { useReplaceQueryParams } from "../../utilities/queryParams/useReplaceQueryParams";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { ReportError } from "../../components/errors/ReportError";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { EmailLink } from "../../components/rxLibrary/Link";
import {
  PERFORMANCE_REPORTS,
  BasePerformanceReport,
} from "./Performance.constants";

const PerformanceContext = createContext<
  | {
      toDate: Dayjs;
      fromDate: Dayjs;
      reportId: number;
      report?: BasePerformanceReport;
      reports: Pharmacy["reports"];
      selectedPharmacies: number[];
      setToDate: (toDate: Dayjs) => void;
      setFromDate: (fromDate: Dayjs) => void;
      setReportId: (reportId?: number) => void;
      setSelectedPharmacies: (pharmacyId: number[]) => void;
    }
  | undefined
>(undefined);

function isReportIdInReports(
  reportId: number | undefined,
  reports: Pharmacy["reports"]
) {
  return !!reportId && reports.some((r) => r.id === reportId);
}

export function PerformanceContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const [fromDate, setFromDate] = useState(() => dayjs().subtract(7, "day"));
  const [toDate, setToDate] = useState(() => dayjs().subtract(1, "day"));
  const [_reportId, _setReportId] = useState<number>();
  const [_selectedPharmacies, _setSelectedPharmacies] = useState<number[]>();
  const [isLSPharmaciesReady, setIsLSPharmaciesReady] = useState(false);

  const replaceQueryParams = useReplaceQueryParams();
  const { pharmacies } = useContext(PharmaciesContext);
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const reports = currentBuyingPharmacy?.reports;

  const reportId = useMemo(() => {
    return _reportId || reports?.[0]?.id;
  }, [_reportId, reports?.[0]?.id]);

  const report = useMemo(() => {
    if (!reportId) return;
    return PERFORMANCE_REPORTS[reportId];
  }, [reportId]);

  const selectedPharmacies = useMemo(() => {
    if (!isLSPharmaciesReady) return;
    if (_selectedPharmacies?.length) return _selectedPharmacies;
    if (pharmacies?.[0]?.id) return [pharmacies?.[0]?.id];
  }, [_selectedPharmacies, pharmacies?.[0]?.id, isLSPharmaciesReady]);

  const setReportId = useCallback(
    (value?: number) => {
      _setReportId(value);
      replaceQueryParams({ report: value?.toString() ?? "" });
      setLocalStorageValue("LastReportKey", value);
    },
    [replaceQueryParams]
  );

  const setSelectedPharmacies = useCallback((value: number[]) => {
    _setSelectedPharmacies(value);
    setLocalStorageValue("LastReportPharmacies", value);
  }, []);

  useEffect(() => {
    if (!reports?.length) return;

    let initialReportId: number | undefined;

    const queryParams = getQueryParams();
    const reportIdQP = queryParams.report;
    if (!!reportIdQP && isString(reportIdQP)) {
      const reportIdNum = parseInt(reportIdQP, 10);
      const isValidReportId = isReportIdInReports(reportIdNum, reports);
      if (isValidReportId) initialReportId = reportIdNum;
    }

    if (!initialReportId) {
      const reportIdLs = getLocalStorageValue<number | undefined>(
        "LastReportKey",
        undefined
      );
      const isValidReportId = isReportIdInReports(reportIdLs, reports);
      if (isValidReportId) initialReportId = reportIdLs;
    }

    _setReportId(initialReportId);
  }, [reports]);

  useEffect(() => {
    if (!pharmacies.length) return;

    const selectedPharmaciesLs = getLocalStorageValue<number[] | undefined>(
      "LastReportPharmacies",
      undefined
    );

    let hasPharmacies = false;
    if (selectedPharmaciesLs?.length) {
      const pharmacyIds = pharmacies.map((p) => p.id);
      hasPharmacies =
        intersection(selectedPharmaciesLs, pharmacyIds).length ===
        selectedPharmaciesLs.length;
    }

    const initialSelectedPharmacies = hasPharmacies
      ? selectedPharmaciesLs
      : undefined;
    _setSelectedPharmacies(initialSelectedPharmacies);
    setIsLSPharmaciesReady(true);
  }, [pharmacies]);

  if (!reports || !pharmacies) {
    return <FullPageLoader text="Loading Reports" />;
  }

  if (reports.length === 0) {
    return (
      <ErrorMessage tw="h-[100dvh]">
        Your account is not set up to show reports. Please contact{" "}
        <EmailLink email="support@daylightrx.com" /> to add reporting to your
        account.
      </ErrorMessage>
    );
  }

  if (!isLSPharmaciesReady) {
    return <FullPageLoader text="Loading Analytics" />;
  }

  if (!reportId || !selectedPharmacies?.length) {
    return <ReportError tw="h-[100dvh]" />;
  }

  return (
    <PerformanceContext.Provider
      value={{
        toDate,
        reports,
        fromDate,
        reportId,
        report,
        selectedPharmacies,
        setToDate,
        setFromDate,
        setReportId,
        setSelectedPharmacies,
      }}
    >
      {children}
    </PerformanceContext.Provider>
  );
}

export function usePerformance() {
  const context = useContext(PerformanceContext);
  if (!context) {
    throw new Error(
      "usePerformance must be used within a PerformanceContextProvider"
    );
  }
  return context;
}
