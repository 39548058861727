import type {
  AddRx,
  BlobPayload,
  DrugWithStats,
  ItemInCart,
  ItemPurchaseDetails,
  OfferedDrug,
  Prescription,
  PrescriptionGroup,
  PrescriptionGroupAdjustment,
} from "../utilities/types";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";
import { ensureArray } from "../utilities/arrays/ensureArray";
import {
  backendFetch,
  backendPost,
  BackendResponse,
} from "./legacy/config/backend-api";

// MAS-TBD: Remove version
type PrescriptionParams = {
  pharmacy_id: string;
  ndc?: string;
  drug_name?: string;
  generic_product_identifier?: string;
  unit_size?: string;
  version?: string;
  use_cache?: string;
};

type PrescriptionGroupsResponse = {
  data?: {
    error?: boolean;
    prescriptionGroups: PrescriptionGroup[];
    offeredDrugs: OfferedDrug[];
  };
  errors?: string[];
};

type DrugsResponseData = {
  error?: boolean;
  drugs: DrugWithStats[];
};

export type PrescriptionGroupsRequestItem = {
  rxNumber?: string;
  currentFillId?: string;
  isCustom?: boolean;
  ndc?: string;
  allowPackSizeSubstitution?: boolean;
  allowManufacturerSubstitution?: boolean;
  numPackages?: number;
  noManufacturerPreference?: boolean;
  purchaseBy?: string;
};

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getPrescriptions(
  pharmacyId: number,
  token: string,
  useLocalJson: boolean,
  useCache: boolean
) {
  // TODO: Check if remove this
  if (useLocalJson) {
    const { default: data } = await import("../api/shopping_p1.json");
    console.log("------- Local Data");
    // console.log(data)
    // this data needs to be fixed or removed
    return data as unknown as BackendResponse<{
      prescriptions: Prescription[];
    }>;
  }

  // MAS-TBD: Remove version
  const params: PrescriptionParams = {
    pharmacy_id: pharmacyId.toString(),
    use_cache: useCache ? "true" : "false",
    version: "2",
  };
  const response = await backendFetch<{ prescriptions: Prescription[] }>(
    `/prescriptions?${new URLSearchParams(params)}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response.data) {
    console.log("------- API Data - P1");
    console.log(response);
    return response;
  } else {
    return {};
  }
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getPrescriptionGroups(
  pharmacyId: number | null,
  token: string,
  requestItems: PrescriptionGroupsRequestItem[]
): Promise<PrescriptionGroupsResponse> {
  // MAS-TBD: Remove version
  const dataPayload = {
    data: {
      prescriptionCartOptions: requestItems,
    },
    version: "2",
  };
  const payload = JSON.stringify(dataPayload);
  const response = await backendPost(
    `/prescription-groups?pharmacy_id=${pharmacyId}`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response) {
    console.log("Got Data getCartPrescriptions");
    return response.json();
  } else {
    return {};
  }
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getSinglePrescription(
  pharmacyId: number,
  token: string,
  ndc: string | null,
  drug_name?: string,
  generic_product_identifier?: string,
  unit_size?: string
) {
  const modifiedNdc = ndc ? ndc.replace(/\D+/g, "") : null;
  const params: PrescriptionParams = { pharmacy_id: pharmacyId.toString() };

  if (modifiedNdc) params.ndc = modifiedNdc;
  if (drug_name) params.drug_name = drug_name;
  if (generic_product_identifier)
    params.generic_product_identifier = generic_product_identifier;
  if (unit_size) params.unit_size = unit_size;

  const response = await backendFetch<DrugsResponseData>(
    `/drugs?${new URLSearchParams(params)}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response?.data) {
    return response.data;
  } else {
    return { drugs: [] };
  }
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function clearJSONBlob(token: string, pharmacyId: number | null) {
  const dataPayload: BlobPayload = {
    data: {
      cart: [],
      inventory: [],
    },
  };
  const payload = JSON.stringify(dataPayload);
  const response = await backendPost(
    `/cart?pharmacy_id=${pharmacyId}`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (response) {
    return response.status;
  } else {
    return 400;
  }
}

export type ServerCartResponseData = {
  createdAt: string;
  data: {
    cart: ItemPurchaseDetails[];
    inventory: ItemInCart[];
    prescriptionGroupAdjustments?: PrescriptionGroupAdjustment[];
  };
  updatedAt: string;
  versionId: number;
};
export type ServerPushBlobResponse = {
  ok: boolean;
  status: number;
  data: ServerCartResponseData | null;
};

const blobVersionIdsForPharmacies: Record<number, number> = {};

export function getBlobVersionId(pharmacyId: number) {
  return blobVersionIdsForPharmacies[pharmacyId] || 0;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function pushJSONBlob(
  token: string,
  pharmacyId: number,
  cart: ItemPurchaseDetails[],
  inventory: ItemInCart[],
  prescriptionGroupAdjustments: PrescriptionGroupAdjustment[],
  second?: boolean
): Promise<ServerPushBlobResponse> {
  const dataPayload: BlobPayload = {
    data: {
      cart: cart,
      inventory: inventory,
      prescriptionGroupAdjustments: prescriptionGroupAdjustments,
      second: second,
    },
    versionId: getBlobVersionId(pharmacyId),
  };

  const payload = JSON.stringify(dataPayload);
  const response = await backendPost(
    `/cart?pharmacy_id=${pharmacyId}`,
    payload,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response) {
    // console.log(typeof response)
    // console.log("Pushed data", response)
    const responseData = await response.json();
    // console.log("pushed data, got back", data)
    if (responseData.data) {
      blobVersionIdsForPharmacies[pharmacyId] = responseData.data.versionId;
    }
    // console.log("pushed data; new blobVersionId", blobVersionId);
    return {
      ok: response.ok,
      status: response.status,
      data: responseData.data,
    };
  } else {
    return {
      ok: false,
      status: 400,
      data: null,
    };
  }
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getJSONBlob(
  pharmacyId: number,
  token: string
): Promise<ServerCartResponseData | Record<string, never>> {
  const params: PrescriptionParams = {
    pharmacy_id: pharmacyId ? pharmacyId.toString() : "0",
  };

  const response = await backendFetch<{
    createdAt: string;
    data: {
      cart: ItemPurchaseDetails[];
      inventory: ItemInCart[];
      prescriptionGroupAdjustments: PrescriptionGroupAdjustment[];
    };
    updatedAt: string;
    versionId: number;
  }>(`/cart?${new URLSearchParams(params)}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  if (response?.data) {
    console.log(`---- Get Blob for ${pharmacyId}`, response.data);
    blobVersionIdsForPharmacies[pharmacyId] = response.data.versionId;
    return response.data;
  } else {
    return {};
  }
}

export async function getRxes(
  client: RequestClient,
  {
    rx,
    pharmacyId,
    ...config
  }: RequestClientConfig<{
    rx: string | string[];
    pharmacyId: number;
  }>
) {
  return client<{
    prescriptions: AddRx[];
    error?: { message: string; missingRxes: string[] };
  }>({
    ...config,
    url: "/rxes",
    params: { pharmacyId, rx: ensureArray(rx) },
  });
}
