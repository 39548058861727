import React, { useCallback, useContext, useEffect, useState } from "react";
import "twin.macro";
import { useLocation } from "react-router";
import "../../../scss/base.scss";
import "../../../scss/shoppingTables.scss";
import "../../../scss/buttonSlot.scss";
import "../../../scss/loader.scss";
import { WideContainer } from "../../../components/containers/WideContainer";
import { NavBar } from "../../../components/NavBar/NavBar";
import { getPrescriptions } from "../../../services/prescriptions";
import { BlobIntervalsProps, Prescription } from "../../../utilities/types";
import { useAuth0 } from "../../../contexts/AuthenticationContext";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../contexts/ShoppingContext/ShoppingContext";
import { useStorageUpdater } from "../../../contexts/StorageContext";
import { useShoppingCartServerUpdater } from "../../../contexts/ShoppingCartServerContext";
import { useServerUpdateNotifications } from "../../../contexts/ServerUpdateNotificationsContext";
import { ShoppingListBar } from "./ShoppingListBar/ShoppingListBar";
import { useShoppingListItems } from "./useShoppingListItems/useShoppingListItems";
import { ShoppingListTableInventory } from "./ShoppingListTableInventory/ShoppingListTableInventory";
import { ShoppingListTablePms } from "./ShoppingListTablePms/ShoppingListTablePms";
import { mergePmsDataWithCartData } from "./mergePmsDataWithCartData";
import { useSearchBarItems } from "./searchBar";

const BLOB_PUSH_TIME = 2000;
const INTERVAL_PATH = "/shoppingList";

export function ShoppingList() {
  const { currentBuyingPharmacyId, currentBuyingPharmacy } = useContext(
    BuyingPharmacyContext
  );
  const [pmsRefreshTimeStamp, setPmsRefreshTimeStamp] = useState<Date>();
  const { getAccessTokenSilently } = useAuth0();
  const { activePrescriptionsPulledEvent } = useServerUpdateNotifications();
  const location = useLocation();
  const { loadCartData, pushBlob, setUseBlob } = useShoppingCartServerUpdater();
  const { serverItemPurchaseDetails } = useShoppingState();
  const {
    removeFromCart,
    setPrescriptionItemsInCart,
    setPrescriptionItemsActive,
  } = useShoppingUpdater();
  const { setWaitButtonMode, addInterval, resetInterval } = useStorageUpdater();
  const {
    cartItemsSort,
    cartItemsFilters,
    cartItemsSortOrder,
    hasCartItemsFilters,
    pmsWindowScrollerRef,
    hideOptimizedPmsItems,
    hideOptimizedInventoryItems,
    inventoryItemsWithVisibility,
    prescriptionItemsWithVisibility,
    setCartItemsSort,
    setSearchBarFilter,
    setCartItemsFilters,
    setCartItemsSortOrder,
    setHideOptimizedPmsItems,
    setHideOptimizedInventoryItems,
  } = useShoppingListItems();

  const searchBarItems = useSearchBarItems(
    inventoryItemsWithVisibility,
    prescriptionItemsWithVisibility
  );

  const [loading, setLoading] = useState(true);
  const [invLoading, setInvLoading] = useState(true);
  const [blobRetrieved, setBlobRetrieved] = useState(false);
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);

  let blobInterval: NodeJS.Timeout | null = null;
  const q = new URLSearchParams(location.search).get("q") as string;
  const { pms: pmsName = "PMS", isSynced: hasPms = false } =
    currentBuyingPharmacy ?? {};

  const getInvData = useCallback(async () => {
    setInvLoading(true);
    const loadedCartData = await loadCartData();
    if (loadedCartData?.inventory) {
      setBlobRetrieved(true);
    }
    setInvLoading(false);
  }, [loadCartData]);

  const getPmsData = useCallback(
    async (useCache = false) => {
      if (!currentBuyingPharmacyId) return
      
      setLoading(true);

      const token = await getAccessTokenSilently();
      const { data } = await getPrescriptions(
        currentBuyingPharmacyId,
        token,
        q === "m",
        useCache
      );

      // If we have data
      if (data?.prescriptions) {
        setWaitButtonMode(true);
        setTimeout(() => {
          setWaitButtonMode(false);
        }, 0);
        setPmsRefreshTimeStamp(new Date());
        setPrescriptions(data.prescriptions);
        setPrescriptionItemsActive(true);
      }
      setLoading(false);
    },
    [
      currentBuyingPharmacyId,
      getPrescriptions,
      setWaitButtonMode,
      getAccessTokenSilently,
      setPrescriptionItemsActive,
    ]
  );

  useEffect(() => {
    if (blobRetrieved) {
      // start pushing
      blobInterval = setInterval(() => {
        const path = location.pathname;
        if (path === INTERVAL_PATH) {
          pushBlob({ second: false, force: false });
        }
      }, BLOB_PUSH_TIME);

      const intervalObj: BlobIntervalsProps = {
        id: currentBuyingPharmacyId,
        intervalId: blobInterval,
      };
      addInterval(intervalObj);
    }
    return () => {
      if (blobInterval) {
        clearInterval(blobInterval);
      }
    };
  }, [blobRetrieved]);

  useEffect(() => {
    if (activePrescriptionsPulledEvent > 0) {
      getPmsData(true);
    }
  }, [activePrescriptionsPulledEvent, getPmsData]);

  useEffect(() => {
    // adjust pms data based on Cart data
    if (prescriptions.length > 0) {
      serverItemPurchaseDetails.forEach((item) => {
        // TODO eventually remove this once sufficient time has passed to clear out old data
        if (item.id.indexOf("_") >= 0) {
          removeFromCart(item.id);
          return;
        }
        if (item.id.indexOf("c") >= 0) {
          return;
        }
        const match = prescriptions.find((p) => p.rxNumber === item.id);
        if (!match) {
          removeFromCart(item.id);
        }
      });

      const mergedPrescriptions = mergePmsDataWithCartData(prescriptions, serverItemPurchaseDetails);
      setPrescriptionItemsInCart(mergedPrescriptions);
    } else {
      setPrescriptionItemsInCart([]);
    }
  }, [
    prescriptions,
    serverItemPurchaseDetails,
    removeFromCart,
    setPrescriptionItemsInCart,
  ]);

  useEffect(() => {
    setPrescriptionItemsInCart([]);

    setLoading(false);
    setInvLoading(true);

    setTimeout(() => {
      getInvData();
      getPmsData();
    }, 0);

    // reset blob intervals
    resetInterval();
  }, [currentBuyingPharmacyId]);

  useEffect(() => {
    setUseBlob(true);
  }, []);

  return (
    <div tw="bg-[#f5f5f5] min-h-screen">
      <NavBar />

      <ShoppingListBar
        searchBarItems={searchBarItems}
        cartItemsSort={cartItemsSort}
        cartItemsFilters={cartItemsFilters}
        cartItemsSortOrder={cartItemsSortOrder}
        setCartItemsSort={setCartItemsSort}
        setSearchBarFilter={setSearchBarFilter}
        setCartItemsFilters={setCartItemsFilters}
        setCartItemsSortOrder={setCartItemsSortOrder}
      />

      <WideContainer tw="relative pt-[48px] pb-[119px]">
        <ShoppingListTableInventory
          loading={invLoading}
          hasCartItemsFilters={hasCartItemsFilters}
          hideOptimizedInventoryItems={hideOptimizedInventoryItems}
          inventoryItemsWithVisibility={inventoryItemsWithVisibility}
          setHideOptimizedInventoryItems={setHideOptimizedInventoryItems}
        />

        {hasPms && (
          <>
            <div tw="mb-[48px]" />

            <ShoppingListTablePms
              pmsName={pmsName}
              loading={loading}
              pmsRefreshTimeStamp={pmsRefreshTimeStamp}
              hasCartItemsFilters={hasCartItemsFilters}
              pmsWindowScrollerRef={pmsWindowScrollerRef}
              hideOptimizedPmsItems={hideOptimizedPmsItems}
              prescriptionItemsWithVisibility={prescriptionItemsWithVisibility}
              getPmsData={getPmsData}
              setHideOptimizedPmsItems={setHideOptimizedPmsItems}
            />
          </>
        )}
      </WideContainer>
    </div>
  );
}
