import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import type { DrugInfo } from "../../../utilities/types";
import { formatWithUnit } from "../../../utilities/formatWithUnit";

export function formatDispenseQuantity({
  drug,
  packQuantity = 0,
  dispensedQuantity,
}: {
  drug: DrugInfo;
  packQuantity?: number;
  dispensedQuantity?: string | number;
}): string {
  const { unitSize, unitQuantity, uom } = drug;

  if (isString(dispensedQuantity)) return dispensedQuantity;

  const quantity = isUndefined(dispensedQuantity)
    ? packQuantity * unitSize * unitQuantity
    : dispensedQuantity;

  const newDispensedQuantity = formatWithUnit(quantity, uom);
  return newDispensedQuantity;
}
