import React from "react";
import "twin.macro";
import { Redirect } from "react-router-dom";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { RedirectHomePage } from "../../../components/RedirectHomePage";
import { NavBar } from "../../../components/NavBar/NavBar";
import { Text } from "../../../components/rxLibrary/typography";
import { WideContainer } from "../../../components/containers/WideContainer";
import { ShoppingListButton } from "../../../components/rxLibrary/buttons";
import { SortBySelect } from "../../../components/rxLibrary/selects/SortBySelect";
import { Box } from "../../../components/rxLibrary/box";
import { useModal } from "../../../components/rxLibrary/modals";
import { ShoppingRxCartPrescriptionItem } from "./shoppingRxItems/ShoppingRxCartPrescriptionItem";
import { ShoppingRxInventoryItem } from "./shoppingRxItems/ShoppingRxInventoryItem";
import { ShoppingRxPrescriptionModal } from "./ShoppingRxPrescriptionModal";
import {
  useShoppingRx,
  ShoppingRxContextProvider,
  SHOPPING_RX_SORT_OPTIONS,
} from "./useShoppingRx/useShoppingRx";
import { ShoppingRxPanel } from "./ShoppingRxPanel";

// TODO: move inventory modal to share file
import { InventoryModal } from "../ShoppingList/ShoppingListTableInventory/InventoryModal/InventoryModal";

function ShoppingRxContent() {
  const {
    sort,
    sortDirection,
    inventoryItems,
    inventorySyncDate,
    prescriptionsItems,
    isInventoryLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    setSort,
    syncInventory,
    setSortDirection,
    syncPrescriptions,
  } = useShoppingRx();

  const inventoryItemsCounter = inventoryItems.length;
  const prescriptionsItemsCounter = prescriptionsItems.length;
  const itemsCounter = prescriptionsItemsCounter + inventoryItemsCounter;

  const {
    isModalOpen: isPrescriptionModalOpen,
    openModal: openPrescriptionModal,
    closeModal: closePrescriptionModal,
  } = useModal();

  const {
    isModalOpen: isInventoryModalOpen,
    openModal: openInventoryModal,
    closeModal: closeInventoryModal,
  } = useModal();

  return (
    <>
      <Box bgColor="grey-5" tw="flex flex-col h-screen overflow-y-hidden">
        <NavBar />

        <div tw="flex flex-col flex-1 overflow-y-hidden">
          <Box bgColor="white-1" tw="relative">
            <WideContainer tw="flex items-center justify-between h-[98px]">
              <div>
                <Text color="blue-2" size={28} block>
                  Today’s Shopping List
                </Text>
              </div>

              <div tw="flex space-x-[37px]">
                <SortBySelect
                  value={sort}
                  options={SHOPPING_RX_SORT_OPTIONS}
                  sortOrder={sortDirection}
                  selectMinWidth={151}
                  onChange={setSort}
                  onSortOrderChange={setSortDirection}
                />

                <ShoppingListButton tw="h-[46px]">
                  {itemsCounter} Items - Continue
                </ShoppingListButton>
              </div>
            </WideContainer>

            <Box
              bgColor="white-1"
              tw="absolute bottom-0 w-full translate-y-full h-[50px]"
            />
          </Box>

          <WideContainer tw="flex-1 content-stretch flex justify-between overflow-y-hidden z-1 space-x-[12px] mb-[20px]">
            <ShoppingRxPanel
              isLoading={isPrescriptionsLoading}
              counter={prescriptionsItemsCounter}
              syncDate={prescriptionsSyncDate}
              onSync={syncPrescriptions}
              openItemsModal={openPrescriptionModal}
            >
              {prescriptionsItems.map((item) => (
                <ShoppingRxCartPrescriptionItem
                  key={item.rxNumber}
                  item={item}
                />
              ))}
            </ShoppingRxPanel>

            <ShoppingRxPanel
              isLoading={isInventoryLoading}
              counter={inventoryItemsCounter}
              syncDate={inventorySyncDate}
              onSync={syncInventory}
              openItemsModal={openInventoryModal}
              inventory
            >
              {inventoryItems.map((item) => (
                <ShoppingRxInventoryItem key={item.rxNumber} item={item} />
              ))}
            </ShoppingRxPanel>
          </WideContainer>
        </div>
      </Box>

      {isPrescriptionModalOpen && (
        <ShoppingRxPrescriptionModal onClose={closePrescriptionModal} />
      )}

      {isInventoryModalOpen && <InventoryModal onClose={closeInventoryModal} />}
    </>
  );
}

export function ShoppingRx() {
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  if (!pharmacy) return <FullPageLoader text="Loading Pharmacy" />;

  const { isSynced, uiSettings } = pharmacy;
  if (!isSynced) return <Redirect to="/shoppingList" />;

  if (!uiSettings.addRx) return <RedirectHomePage />;

  return (
    <ShoppingRxContextProvider>
      <ShoppingRxContent />
    </ShoppingRxContextProvider>
  );
}
