import React, { useContext, useEffect, useState } from "react";
import "twin.macro";
import "../../scss/base.scss";
import "../../scss/previousShoppingLists.scss";
import type { Pharmacy } from "../../utilities/types";
import {NavBar} from "../../components/NavBar/NavBar";
import {WideContainer} from "../../components/containers/WideContainer";
import {
  RecentOptimization, SupplierOrderItemRecommendation,
  SupplierOrder,
  uniqueCatalogDrugID, OptimizationSelections
} from "../../services/types";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import {useAuth0} from "../../contexts/AuthenticationContext";
import {getRecentOptimizations} from "../../services/legacy/optimizations";
import {Button} from "../../components/rxLibrary/buttons";
import {Text} from "../../components/rxLibrary/typography";

function todayOrYesterdayOrDaysAgo(date: Date): string {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const dateDay = date.getDate();
  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();
  if (currentDay === dateDay && currentMonth === dateMonth && currentYear === dateYear) {
    return "Today";
  }
  const yesterday = new Date(currentDate);
  yesterday.setDate(yesterday.getDate() - 1);
  if (yesterday.getDate() === dateDay && yesterday.getMonth() === dateMonth && yesterday.getFullYear() === dateYear) {
    return "Yesterday";
  }
  return "Days Ago";
}

function presentDate(dateString: string, reaffirmDate: boolean): string {
  const date = new Date(dateString);
  return `${todayOrYesterdayOrDaysAgo(date)} - ${date.toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})}${reaffirmDate ? ( ` - ${date.toLocaleDateString("en-US", {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}`) : ''}`;
}

function listSummary(items: any[] | undefined) {
  if (!items) {
    return "No Items";
  }
  if (items.length === 1) {
    return "1 Item";
  }
  return `${items.length} Items`;
}

function listCount(selections: OptimizationSelections | undefined): number {
  if (!selections) {
    return 0;
  }
  let totalListCount = selections.supplierOrders.length;
  totalListCount += (selections.unfilledFuturePrescriptionGroups.length > 0) ? 1 : 0;
  totalListCount += (selections.unavailable.length > 0) ? 1 : 0;
  return totalListCount;
}

function orderSummary(selections: OptimizationSelections | undefined): string {
  if (!selections) {
    return "No Items";
  }

  const items = selections.supplierOrders.reduce((prev, acc) => {
    return prev.concat(acc.items)
  }, [] as SupplierOrderItemRecommendation[]);

  let totalItemCount = items.length;

  totalItemCount += selections.unfilledFuturePrescriptionGroups.length;
  totalItemCount += selections.unavailable.length;

  if (totalItemCount === 0) {
    return "No Items";
  }

  const noun = totalItemCount === 1 ? "Item" : "Items";
  const visitedItemsIds = selections.visitedItemsIds;
  if (!visitedItemsIds || visitedItemsIds.length === 0) {
    return `${totalItemCount} ${noun}`;
  }
  const visitedItemCount = items.filter(item => visitedItemsIds.includes(uniqueCatalogDrugID(item))).length;
  if (visitedItemCount) {
    return `${totalItemCount} ${noun}, ${visitedItemCount} Visited`;
  } else {
    return `${totalItemCount} ${noun}`;
  }
}

function getSupplierDisplayName(supplierOrder: SupplierOrder, currentBuyingPharmacy: Pharmacy | null): string {
  if (!currentBuyingPharmacy) {
    return "Unavailable Supplier";
  }
  const supplier = currentBuyingPharmacy.suppliers.find(supplier => supplier.id === supplierOrder.supplierId);
  return supplier ? supplier.displayName : "Unavailable Supplier";
}

export function PreviousShoppingLists() {
  const { getAccessTokenSilently } = useAuth0();
  const { currentBuyingPharmacyId, currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const [recentOptimizations, setRecentOptimizations] = useState<RecentOptimization[]>([]);
  useEffect(() => {
    if (!currentBuyingPharmacyId) return;
    (async () => {
      const token = await getAccessTokenSilently();
      // fetch recent optimizations
      const recentOptimizations = await getRecentOptimizations(token, currentBuyingPharmacyId);
      setRecentOptimizations(recentOptimizations.cartOptimizations);
    })();
  }, [currentBuyingPharmacyId]);

  return (
    <div className="previousShoppingLists" tw="bg-[#f5f5f5] min-h-screen">
      <NavBar />
     <WideContainer tw="pt-[68px] pb-[200px]">
        <Text size={32} block>Recent Suppliers&apos; Shopping Lists (Past 24 Hours)</Text>
        <div>
          {recentOptimizations.length === 0 && (
            <div className="card flex flex-col items-center" style={{paddingTop: "64px", paddingBottom: "64px"}}>
              <Text tw="mb-[18px] text-gray-500" weight="bold" size={20} style={{borderBottom: "1px solid rgb(107, 114, 128)", paddingBottom: "8px"}}>
                No Shopping Lists were created in the last 24hr.
              </Text>
              <Text tw="w-[350px] text-gray-500 text-center" size={16}>
                We preserve shopping list optimizations that were created in the last 24 hours.
                Older lists will not be not preserved.
              </Text>
            </div>
          )}
          {recentOptimizations.length > 0 && recentOptimizations.map((optimization) => (
            <div className="card" key={optimization.id}>
              <div>
                <Text weight="bold" size={20}>
                  {presentDate(optimization.createdAt, true)}
                </Text>
                <Text tw="mb-[18px] uppercase" size={14}>
                  Updated {presentDate(optimization.updatedAt, false)}
                </Text>
                <Text tw="mb-[18px] uppercase" size={18}>
                  {optimization.createdBy.full_name}
                </Text>
                <Button
                  tw="w-[140px] mb-4"
                  onClick={() => {
                    window.location.href = "/shoppingListThree?from=list&optimizedCartId=" + optimization.id
                  }}>
                  <Text color="white-1" block>
                    {listCount(optimization.selections) === 1 ? "Revisit This List" : "Revisit These Lists"}
                  </Text>
                </Button>
              </div>
              <div>
                <Text tw="mb-[18px]" weight="bold" size={20}>({orderSummary(optimization.selections)})</Text>
                <ul className="supplierOrderSummary">
                  {optimization.selections?.supplierOrders?.filter((supplierOrder) => { return supplierOrder.items.length > 0}).map((supplierOrder, index) => (
                    <li key={index}>
                      <Text className="supplierOrderSummary__supplierName">
                        {getSupplierDisplayName(supplierOrder, currentBuyingPharmacy)} Shopping List
                      </Text>
                      <Text className="supplierOrderSummary__itemCount">
                          ({listSummary(supplierOrder.items)})
                      </Text>
                    </li>
                  ))}
                  {optimization.selections && optimization.selections.unfilledFuturePrescriptionGroups.length > 0 && (
                    <li>
                      <Text className="supplierOrderSummary__supplierName">
                        Buy Later
                      </Text>
                      <Text className="supplierOrderSummary__itemCount">
                        ({listSummary(optimization.selections.unfilledFuturePrescriptionGroups)})
                      </Text>
                    </li>
                  )}
                  {optimization.selections && optimization.selections.unavailable.length > 0 && (
                    <li>
                      <Text className="supplierOrderSummary__supplierName">
                        Unavailable
                      </Text>
                      <Text className="supplierOrderSummary__itemCount">
                        ({listSummary(optimization.selections.unavailable)})
                      </Text>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
     </WideContainer>
    </div>
  );
}
