import { useMemo } from "react";
import { styled } from "twin.macro";
import { formatPrescriberName } from "../../../../utilities/prescriptions/formatPrescriberName";
import {
  ItemInCart,
  PrescriptionQuantityItem,
} from "../../../../utilities/types";
import { Table } from "../../../../components/Table";
import { formatPurchaseByToDayjs } from "../../../../utilities/dates/purchaseBy/formatPurchaseByToDayjs";
import { SHORT_DATE_FORMAT } from "../../../../utilities/dates/dates.constants";
import { formatDateToFormat } from "../../../../utilities/dates/formatDateToFormat";
import { PrescriptionTableFooter } from "./PrescriptionTableFooter";
import { COLUMNS } from "./PrescriptionTable.columns";

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;

  thead th {
    font-weight: normal;
    text-align: start;
  }
`;

function formatData(rxList: ItemInCart[]) {
  if (!rxList || rxList.length === 0) return [];

  const data = rxList.map((item) => {
    const purchaseByDate = formatPurchaseByToDayjs(item.purchaseBy);
    const purchaseByStr = formatDateToFormat(purchaseByDate, SHORT_DATE_FORMAT);
    const dateFilled = formatDateToFormat(item?.fillDate, SHORT_DATE_FORMAT);
    const prescriber = formatPrescriberName(item.prescriber);

    return {
      identifier: item,
      dateFilled: dateFilled ?? "-",
      prescriber,
      dispensedQty: item,
      purchaseBy: purchaseByStr ?? "TODAY",
    };
  });

  return data;
}

export function PrescriptionTable({
  prescription,
}: {
  prescription: PrescriptionQuantityItem;
}) {
  const { rxList, purchaseBy } = prescription;
  const data = useMemo(() => formatData(rxList), [rxList]);

  return (
    <TableWrapper>
      <Table
        showFooter
        data={data}
        columns={COLUMNS}
        isStickyHeader={false}
        footer={
          <PrescriptionTableFooter purchaseBy={purchaseBy} rxList={rxList} />
        }
      />
    </TableWrapper>
  );
}
