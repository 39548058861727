import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import isString from "lodash/isString";
import semver from "semver";
import type { PageAlert } from "../utilities/types";
import EnhancedClientConfigContext from "../enhanced/EnhancedClientConfigContext";
import { useBuyingPharmacy } from "../contexts/BuyingPharmacyContext";
import { getPageAlerts } from "../services/app";
import { Box } from "./rxLibrary/box";
import { IconBell } from "./rxLibrary/icons";
import { Text } from "./rxLibrary/typography";
import { WideContainer } from "./containers/WideContainer";

const PAGE_INTERVAL_MS = 1000 * 60 * 5;

const PAGE_ALERTS_BLACKLISTED_PATHS = [
  "/admin",
  "/shoppingListPrint",
  "/supplierNavigation",
  "/supplierBrowserBar",
  "/supplierSafetyScreen",
];

export function PageAlerts() {
  const location = useLocation();
  const { pathname } = location;

  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { enhancedClientActive, enhancedClientVersion } = useContext(
    EnhancedClientConfigContext
  );

  const [msgs, setMsgs] = useState<string[]>();
  const [alerts, setAlerts] = useState<PageAlert[]>();

  useEffect(() => {
    updateAlerts();
    const intervalId = setInterval(updateAlerts, PAGE_INTERVAL_MS);
    return () => clearInterval(intervalId);

    async function updateAlerts() {
      try {
        // Don't check if the user is offline
        if (!navigator.onLine) return;

        const allAlerts = await getPageAlerts();
        const newAlerts = allAlerts.filter((alert) => {
          const { enabled, message } = alert;
          if (!message || !isString(message)) return false;
          return enabled === true;
        });

        setAlerts(newAlerts);
      } catch (error) {
        console.error("Failed to fetch page alerts", error);
      }
    }
  }, []);

  useEffect(() => {
    if (!alerts?.length) {
      setMsgs(undefined);
      return;
    }

    const firstPath = pathname.split("/").find((s) => s.length);
    if (!firstPath) {
      setMsgs(undefined);
      return;
    }

    const path = `/${firstPath}`;
    const newMsgs = alerts
      .filter((alert) => {
        const {
          pages,
          blackListedPages,
          pharmacyIds,
          electronOnly,
          electronMaxVersion,
        } = alert;

        if (!!electronOnly && !enhancedClientActive) return false;

        if (enhancedClientVersion && electronMaxVersion) {
          if (semver.gt(enhancedClientVersion, electronMaxVersion)) {
            return false;
          }
        }

        if (PAGE_ALERTS_BLACKLISTED_PATHS.includes(path)) return false;

        if (pages?.length && !pages.includes(path)) return false;

        if (blackListedPages?.length && blackListedPages.includes(path)) {
          return false;
        }

        if (pharmacyIds?.length) {
          if (path === "/manufacturer") return false;
          if (!pharmacyId) return false;
          if (!pharmacyIds.includes(pharmacyId)) return false;
        }

        return true;
      })
      .map((alert) => alert.message);

    setMsgs(newMsgs);
  }, [
    alerts,
    pathname,
    pharmacyId,
    enhancedClientActive,
    enhancedClientVersion,
  ]);

  if (!msgs?.length) return null;
  return msgs.map((msg, i) => (
    <Box key={i + msg} tw="relative mb-[3px] z-50" bgColor="red-1">
      <WideContainer tw="flex items-center justify-center py-[15px] space-x-[8px]">
        <div>
          <IconBell color="white-1" />
        </div>

        <Text color="white-1" weight="bold" size="large">
          {msg}
        </Text>
      </WideContainer>
    </Box>
  ));
}
