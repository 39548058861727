import dayjs, { Dayjs } from "dayjs";
import isDate from "lodash/isDate";
import isString from "lodash/isString";
import { SHORT_DATE_FORMAT } from "./dates.constants";

export function formatDateToFormat(
  value?: string | Date | Dayjs,
  format = SHORT_DATE_FORMAT
): string | void {
  let date: Dayjs | undefined;

  if (isString(value) && !!value) {
    const parsedDate = new Date(value);
    const newDate = new Date(parsedDate.getUTCFullYear(),
                             parsedDate.getUTCMonth(),
                             parsedDate.getUTCDate(),
                             parsedDate.getHours(),
                             parsedDate.getMinutes(),
                             parsedDate.getSeconds());
    date = dayjs(newDate);
  } else if (isDate(value)) {
    date = dayjs(value);
  } else if (dayjs.isDayjs(value)) {
    date = value;
  }

  if (!!date && date.isValid()) {
    const formattedDate = date.format(format ?? SHORT_DATE_FORMAT);
    return formattedDate;
  }
}
