import React, { useMemo } from "react";
import type { PerformanceReportComponent } from "./Performance.constants";
import type { Dayjs } from "dayjs";
import { ReportWrapper } from "./ReportWrapper";
import { PerformanceReportError } from "./PerformanceReportError";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { useReportRequest } from "./useReportRequest";

export function PerformanceReportChart({
  reportId,
  startDate,
  endDate,
  selectedPharmacies,
  Report,
}: {
  reportId: number;
  startDate: Dayjs;
  endDate: Dayjs;
  selectedPharmacies: number[];
  Report: PerformanceReportComponent;
}) {
  const config = useMemo(() => {
    return { reportId, selectedPharmacies, startDate, endDate };
  }, [reportId, selectedPharmacies, startDate, endDate]);

  const { isLoading, error, data } = useReportRequest(config);

  return (
    <>
      {!!data && (
        <ReportWrapper>
          <div tw="w-full h-full overflow-scroll">
            <Report data={data} />
          </div>
        </ReportWrapper>
      )}
      {!!error && <PerformanceReportError />}
      {isLoading && <FullPageLoader />}
    </>
  );
}
