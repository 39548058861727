import { ErrorMessage } from "../../components/errors/ErrorMessage";

export function PerformanceReportError() {
  return (
    <ErrorMessage tw="h-[calc(100dvh - 236px)]">
      There was a problem loading this report. Very sorry! We have been notified
      and we will look into this right away.
    </ErrorMessage>
  );
}
