import React, { useMemo } from "react";
import "twin.macro";
import p from "pluralize";
import { IconClock } from "../../../../../../../components/rxLibrary/icons";
import { CutOffTime } from "../../../../../../../components/CutOffTime";
import { Text } from "../../../../../../../components/rxLibrary/typography";
import {
  DrugInfo,
  Supplier,
  CatalogInfo,
} from "../../../../../../../utilities/types";
import { currencyWithCentsUnlessGreaterThanOrEqualToOneThousand } from "../../../../../../../utilities/numbers/currency";
import { useShoppingState } from "../../../../../../../contexts/ShoppingContext/ShoppingContext";
import { getMinShipOrderAmount } from "../../../../../../../utilities/shipping/shipping";
import { buildSupplierColShippingWarning } from "./buildSupplierColShippingWarning";
import {
  CatalogDrug_All
} from "../../../../../../../services/types";

export function SupplierCol({
  drug,
  supplier,
  catalogInfo,
  numPackages,
  itemToRemove,
  supplierOrderBuyingCost,
  supplierOrderItemsCount,
}: {
  drug: DrugInfo;
  supplier: Supplier;
  catalogInfo: CatalogInfo;
  numPackages: number;
  itemToRemove?: CatalogDrug_All;
  supplierOrderBuyingCost?: number;
  supplierOrderItemsCount?: number;
}) {
  const { getShippingFeeSimulation } = useShoppingState();
  const { shippingConfig, displayName } = supplier;
  const cutoffTimes = shippingConfig?.cutoffTimes ?? "0:0";

  const shippingWarning = useMemo(() => {
    const itemToAdd = { drug: { id: drug.id}, catalogInfo: {id: catalogInfo.id}, referenceData: {catalogInfo, drugInfo: drug}, numPackages };
    const shippingFeeSimulation = getShippingFeeSimulation(supplier, {
      itemToAdd,
      itemToRemove,
    });
    return buildSupplierColShippingWarning({ supplier, shippingFeeSimulation });
  }, [
    drug,
    supplier,
    catalogInfo,
    numPackages,
    itemToRemove,
    getShippingFeeSimulation,
  ]);

  const priceAndItemCounterText = useMemo(() => {
    if (!supplierOrderItemsCount || !supplierOrderBuyingCost) return;

    const total = currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
      supplierOrderBuyingCost
    );
    const itemsStr = p("item", supplierOrderItemsCount);
    return `${total} / ${supplierOrderItemsCount} ${itemsStr}`;
  }, [supplierOrderItemsCount, supplierOrderBuyingCost]);

  const minShipText = useMemo(() => {
    return getMinShipOrderAmount(shippingConfig);
  }, [shippingConfig]);

  return (
    <div tw="flex-1 min-w-[180px] border-r px-[12px]">
      <div tw="pb-[4px]">
        <Text block size="large" weight="bold">
          {displayName}
        </Text>
      </div>

      {priceAndItemCounterText && (
        <div tw="border-t py-[2px]">
          <Text block>{priceAndItemCounterText}</Text>
        </div>
      )}

      <div tw="border-t py-[2px] flex">
        <IconClock tw="mr-1" />
        <Text>
          <CutOffTime cutoffTimes={cutoffTimes} cutoffOnly />
        </Text>
      </div>

      <div tw="border-t py-[2px]">
        <Text block>$ Min. - {minShipText}</Text>
      </div>

      {shippingWarning && (
        <div tw="border-t py-[2px]">
          <Text block weight="bold" color="red-1">
            {shippingWarning}
          </Text>
        </div>
      )}
    </div>
  );
}
