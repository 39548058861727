import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import keyBy from "lodash/keyBy";
import { Supplier } from "../../../../utilities/types";
import { useShoppingState } from "../../../../contexts/ShoppingContext/ShoppingContext";
import BuyingPharmacyContext from "../../../../contexts/BuyingPharmacyContext";
import { SupplierItemChangeOperation } from "../../../../utilities/shoppingListTypes";
import { SupplierCol } from "../tables/table/columns/SupplierCol/SupplierCol";
import { Text } from "../../../../components/rxLibrary/typography";
import { DrugPriceCol } from "../../../../components/shoppingList/table/columns/DrugPriceCol";
import { DrugDetailsCol } from "../../../../components/shoppingList/table/columns/DrugDetailsCol";
import { normalizePackQuantity } from "../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import { QuantitySelectorCol } from "../tables/table/columns/QuantitySelectorCol";
import { ButtonCol } from "../tables/table/columns/ButtonCol";
import { TableModalTable } from "../tables/table/tableModal/TableModalTable";
import { TableModalTableRow } from "../tables/table/tableModal/TableModalTableRow";
import { TableModal } from "../tables/table/tableModal/TableModal";
import { SuppliersModalSuggestedItem } from "./SuppliersModalSuggestedItem";
import { buildSuppliersModalShippingWarning } from "./buildSuppliersModalShippingWarning";
import {
  SupplierOrder_All,
  CatalogDrug_All,
  SupplierOrderItemRecommendation_All,
  uniqueCatalogDrugID
} from "../../../../services/types";

type DrugQuantities = Record<string, number>;

type SupplierData = {
  id: string;
  supplier: Supplier;
  alternative: CatalogDrug_All;
  supplierOrder: SupplierOrder_All;
}[];

type NoSupplierData = Omit<SupplierData[number], "supplierOrder">[];

type ModalData = {
  supplierData: SupplierData;
  noSupplierData: NoSupplierData;
};

export function SuppliersModal({
  data,
  isOpen,
  suggested,
  supplierName,
  callBack,
}: {
  data: CatalogDrug_All[];
  isOpen: boolean;
  suggested?: SupplierOrderItemRecommendation_All;
  supplierName?: string;
  callBack: (operations?: SupplierItemChangeOperation[]) => void;
}) {
  const { optimizeCartResponse, getShippingFeeSimulation } =
    useShoppingState();
  const { getSupplierById } = useContext(BuyingPharmacyContext);
  const supplierOrders = optimizeCartResponse?.data?.selections.supplierOrders;

  const [drugQuantities, setDrugQuantities] = useState<DrugQuantities>({});
  const [{ supplierData, noSupplierData }, setModalData] = useState<ModalData>({
    supplierData: [],
    noSupplierData: [],
  });

  const shippingWarning = useMemo(() => {
    if (!suggested) return;

    const supplier = getSupplierById(suggested.referenceData.catalogInfo.supplierId);
    if (!supplier) return;

    const shippingFeeSimulation = getShippingFeeSimulation(supplier, {
      itemToRemove: suggested,
    });
    return buildSuppliersModalShippingWarning({
      supplier,
      shippingFeeSimulation,
    });
  }, [suggested, getSupplierById, getShippingFeeSimulation]);

  const shippingCostWarning = !!shippingWarning && (
    <Text weight="bold" color="red-1">
      {shippingWarning}
    </Text>
  );

  const updateDrugQuantity = useCallback(
    ({ id, value }: { id: string; value: number }) => {
      const newDrugQuantities = {
        ...drugQuantities,
        [id]: value,
      };
      setDrugQuantities(newDrugQuantities);
    },
    [drugQuantities]
  );

  const closeModal = useCallback(() => {
    callBack();
  }, [callBack]);

  useEffect(() => {
    const supplierData: SupplierData = [];
    const noSupplierData: NoSupplierData = [];
    const suppliersOrdersById = keyBy(supplierOrders, "supplierId");

    data.forEach((alternative) => {
      const supplier = getSupplierById(alternative.referenceData.catalogInfo.supplierId);
      if (!supplier) return;

      const id = uniqueCatalogDrugID(alternative);
      const supplierOrder = suppliersOrdersById[supplier.id];
      if (supplierOrder) {
        supplierData.push({ id, alternative, supplier, supplierOrder });
      } else {
        noSupplierData.push({ id, alternative, supplier });
      }
    });

    supplierData.sort((a, b) => {
      return a.alternative.referenceData.catalogInfo.price - b.alternative.referenceData.catalogInfo.price;
    });

    setModalData({ supplierData, noSupplierData });
  }, [data, supplierOrders, getSupplierById]);

  useEffect(() => {
    const newDrugQuantities = data.reduce<DrugQuantities>(
      (acc, alternative) => {
        const id = uniqueCatalogDrugID(alternative);
        const newNumPackages = normalizePackQuantity(alternative.numPackages);
        acc[id] = newNumPackages;
        return acc;
      },
      {}
    );
    setDrugQuantities(newDrugQuantities);
  }, [data]);

  if (!isOpen) return null;
  return (
    <TableModal
      open
      onClose={closeModal}
      title="Change Item"
      header={
        !!suggested && (
          <SuppliersModalSuggestedItem
            suggested={suggested}
            supplierName={supplierName}
            callBack={callBack}
          />
        )
      }
    >
      {supplierData.length > 0 && (
        <TableModalTable title="Other Options" header={shippingCostWarning}>
          {supplierData.map((data) => {
            const { id, supplier, alternative, supplierOrder } = data;
            const { drugInfo, catalogInfo } = alternative.referenceData;
            const numPackages = drugQuantities[id];
            const supplierOrderBuyingCost = supplierOrder.buyingCost;
            const supplierOrderItemsCount = supplierOrder.items.length;

            return (
              <TableModalTableRow key={id}>
                <SupplierCol
                  drug={drugInfo}
                  supplier={supplier}
                  numPackages={numPackages}
                  catalogInfo={catalogInfo}
                  itemToRemove={suggested}
                  supplierOrderBuyingCost={supplierOrderBuyingCost}
                  supplierOrderItemsCount={supplierOrderItemsCount}
                />

                <DrugDetailsCol drug={drugInfo} catalogInfo={catalogInfo} />

                <DrugPriceCol drug={drugInfo} catalogInfo={catalogInfo} />

                <QuantitySelectorCol
                  drug={drugInfo}
                  numPackages={numPackages}
                  catalogInfo={catalogInfo}
                  updateNumPackages={(value) => {
                    updateDrugQuantity({ id, value });
                  }}
                />

                <ButtonCol
                  text="Select"
                  quantity={numPackages}
                  catalogInfo={catalogInfo}
                  onClick={() => {
                    if (suggested) {
                      callBack([
                        {
                          type: "changeQtyAlt",
                          quantity: numPackages,
                          newItem: alternative,
                          oldItem: suggested,
                        },
                        {
                          type: "changeSupplierItem",
                          newItem: alternative,
                          oldItem: suggested,
                        },
                      ]);
                    } else callBack();
                  }}
                />
              </TableModalTableRow>
            );
          })}
        </TableModalTable>
      )}

      {noSupplierData.length > 0 && (
        <TableModalTable title="Yet More Options" header={shippingCostWarning}>
          {noSupplierData.map((data) => {
            const { id, supplier, alternative } = data;
            const { drugInfo, catalogInfo } = alternative.referenceData;
            const numPackages = drugQuantities[id];

            return (
              <TableModalTableRow key={id}>
                <SupplierCol
                  drug={drugInfo}
                  supplier={supplier}
                  itemToRemove={suggested}
                  catalogInfo={catalogInfo}
                  numPackages={numPackages}
                />

                <DrugDetailsCol drug={drugInfo} catalogInfo={catalogInfo} />

                <DrugPriceCol drug={drugInfo} catalogInfo={catalogInfo} />

                <QuantitySelectorCol
                  drug={drugInfo}
                  numPackages={numPackages}
                  catalogInfo={catalogInfo}
                  updateNumPackages={(value) => {
                    updateDrugQuantity({ id, value });
                  }}
                />

                <ButtonCol
                  text="Select"
                  quantity={numPackages}
                  catalogInfo={catalogInfo}
                  onClick={() => {
                    if (suggested) {
                      callBack([
                        {
                          type: "addToSupplierOrders",
                          newItem: alternative,
                        },
                        {
                          type: "changeQtyAlt",
                          quantity: numPackages,
                          newItem: alternative,
                          oldItem: suggested,
                        },
                        {
                          type: "changeSupplierItem",
                          newItem: alternative,
                          oldItem: suggested,
                        },
                      ]);
                    } else callBack();
                  }}
                />
              </TableModalTableRow>
            );
          })}
        </TableModalTable>
      )}
    </TableModal>
  );
}
