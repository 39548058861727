import { useCallback } from "react";
import "twin.macro";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { Button } from "../../../../../../components/rxLibrary/buttons";

const HIDDEN_DATE_INPUT_PICKER_STYLE = {
  height: 0,
  visibility: "hidden",
  width: 0,
  padding: 0,
  border: 0,
  margin: 0,
} as const;

function CalendarFooter({
  showToday,
  onClick,
}: {
  showToday: boolean;
  onClick: () => void;
}) {
  return (
    <div tw="flex items-center justify-center p-[10px] space-x-[10px]">
      <Button onClick={close} variant="text-2">
        Cancel
      </Button>
      {showToday && (
        <Button onClick={onClick} variant="text-2">
          Buy Today
        </Button>
      )}
    </div>
  );
}

export function ShoppingRxItemHeaderDatePicker({
  date,
  open,
  disableToday,
  close,
  onChange,
  onOpenChange,
}: {
  date?: Dayjs;
  open?: boolean;
  disableToday?: boolean;
  close: () => void;
  onChange: (date?: Dayjs) => void;
  onOpenChange: (open: boolean) => void;
}) {
  const handleChange = useCallback(
    (date: Dayjs) => {
      const newBuyByDate = date.isSame(new Date(), "day") ? undefined : date;
      onChange(newBuyByDate);
    },
    [onChange]
  );

  const disabledDate = useCallback(
    (date: Dayjs) => {
      const now = new Date();
      if (disableToday && date.isSame(now, "day")) return true;
      const isDisabled = date.isBefore(now, "day");
      return isDisabled;
    },
    [disableToday]
  );

  const setToday = useCallback(() => {
    handleChange(dayjs());
    close();
  }, [handleChange, close]);

  if (!open) return null;
  return (
    <DatePicker
      open={open}
      value={date}
      style={HIDDEN_DATE_INPUT_PICKER_STYLE}
      showNow={false}
      onChange={handleChange}
      onOpenChange={onOpenChange}
      disabledDate={disabledDate}
      renderExtraFooter={() => (
        <CalendarFooter showToday={!disableToday} onClick={setToday} />
      )}
    />
  );
}
