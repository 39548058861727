import { useCallback, useMemo } from "react";
import "twin.macro";
import type { Dayjs } from "dayjs";
import type { ItemInCart } from "../../../../utilities/types";
import { useShoppingUpdater } from "../../../../contexts/ShoppingContext/ShoppingContext";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";
import { normalizePackQuantity } from "../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import { Box } from "../../../../components/rxLibrary/box";
import { QtySelect } from "../../../../components/rxLibrary/selects/QtySelect";
import { usePrescriptionNote } from "../../../../components/PrescriptionNote/usePrescriptionNote";
import { ShoppingRxItemPanel } from "./components/ShoppingRxItemPanel/ShoppingRxItemPanel";
import { ShoppingRxItemHeader } from "./components/ShoppingRxItemHeader/ShoppingRxItemHeader";

function InventoryQtySelect({ item }: { item: ItemInCart }) {
  const { updatePrescriptionPackQuantity } = useShoppingUpdater();
  const directDeal = item.directDeals?.[0];

  const quantitiesConfig = useMemo(() => {
    return {
      bundleSize: directDeal?.bundleSize,
      directDealLabel: true,
      includeZero: true,
      zeroLabel: "Delete",
    };
  }, [directDeal]);

  const packQuantity = useMemo(() => {
    return normalizePackQuantity(item.packQuantity);
  }, [item.packQuantity]);

  const updatePackQuantity = useCallback(
    (value: number) => {
      updatePrescriptionPackQuantity(item, value);
    },
    [item, updatePrescriptionPackQuantity]
  );

  return (
    <QtySelect
      value={packQuantity}
      quantitiesConfig={quantitiesConfig}
      onChange={updatePackQuantity}
    />
  );
}

export function ShoppingRxInventoryItem({ item }: { item: ItemInCart }) {
  const id = useMemo(() => getPrescriptionId(item), [item]);

  const {
    deletePrescription,
    updatePrescriptionNote,
    updatePrescriptionPackSize,
    updatePrescriptionPurchaseBy,
    updatePrescriptionManufacturer,
  } = useShoppingUpdater();

  const {
    hasPrescriptionNote: hasItemNote,
    prescriptionNotePopover: itemNotePopover,
    openPrescriptionNotePopover: openItemNotePopover,
  } = usePrescriptionNote({
    prescription: item,
    updatePrescriptionNote,
  });

  const updateItemPackSize = useCallback(
    (value: boolean) => {
      updatePrescriptionPackSize(item, value);
    },
    [item]
  );

  const updateItemManufacturer = useCallback(
    (value: boolean) => {
      updatePrescriptionManufacturer(item, value);
    },
    [item, updatePrescriptionManufacturer]
  );

  const removeItem = useCallback(() => {
    deletePrescription(id);
  }, [id, deletePrescription]);

  const updateItemPurchaseBy = useCallback(
    (value?: Dayjs) => {
      updatePrescriptionPurchaseBy(id, value);
    },
    [id, updatePrescriptionPurchaseBy]
  );

  return (
    <div tw="rounded-lg p-[5px] h-[128px] bg-[#fff199]">
      <ShoppingRxItemHeader
        isInCart
        inventory
        item={item}
        hasItemNote={hasItemNote}
        removeItem={removeItem}
        openItemNotePopover={openItemNotePopover}
        updateItemPurchaseBy={updateItemPurchaseBy}
      />

      <ShoppingRxItemPanel
        inventory
        item={item}
        itemNotePopover={itemNotePopover}
        updateItemPackSize={updateItemPackSize}
        updateItemManufacturer={updateItemManufacturer}
      >
        <Box
          tw="flex items-center justify-center border-r w-[134px] px-[10px]"
          borderColor="grey-2"
        >
          <InventoryQtySelect item={item} />
        </Box>
      </ShoppingRxItemPanel>
    </div>
  );
}
