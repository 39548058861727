import { useContext, useEffect, useMemo } from "react";
import "twin.macro";
import {
  Route,
  Switch,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { withAuthenticationRequired } from "./contexts/AuthenticationContext";
import { useBuyingPharmacy } from "./contexts/BuyingPharmacyContext";
import { NavBar } from "./components/NavBar/NavBar";
import { RoleProtectedRoute } from "./components/RoleProtectedRoute";
import { Compare } from "./pages/Compare/Compare";
import { PharmacyLoader } from "./components/PharmacyLoader";
import { PageAlerts } from "./components/PageAlerts";
import EnhancedSupplierNavBar from "./enhanced/EnhancedSupplierNavBar";
import EnhancedSupplierBrowserBar from "./enhanced/EnhancedSupplierBrowserBar";
import { EnhancedSupplierAccount } from "./enhanced/EnhancedSupplierAccount/EnhancedSupplierAccount";
import { Performance } from "./pages/Performance/Performance";
import { UnauthorizedPage } from "./pages/UnauthorizedPage";
import { UserBusinessRoles } from "./utilities/types";
import AppContext from "./contexts/AppContext";
import EnhancedScreenLockInactivity from "./enhanced/EnhancedScreenLockInactivity";
import TimerContext from "./contexts/TimerContext";
import { ShoppingList } from "./pages/shopping/ShoppingList/ShoppingList";
import { ShoppingListTwo } from "./pages/shopping/ShoppingListTwo/ShoppingListTwo";
import { ShoppingListThree } from "./pages/shopping/ShoppingListThree/ShoppingListThree";
import { ShoppingListPrint } from "./pages/shopping/ShoppingListPrint";
import { PreviousShoppingLists } from "./pages/shopping/PreviousShoppingLists";
import { ShoppingRx } from "./pages/shopping/ShoppingRx/ShoppingRx";
import EnhancedShoppingListSearchTestRoute from "./enhanced/EnhancedShoppingListSearchTestRoute";
import EnhancedClientConfigContext from "./enhanced/EnhancedClientConfigContext";
import { ReloadPageAlert } from "./components/ReloadPageAlert";
import { Manufacturer } from "./pages/Manufacturer/Manufacturer";
import { Admin } from "./pages/Admin/Admin";
import { SelectDrug } from "./pages/SelectDrug";
import { DemoMode } from "./pages/DemoMode";
import { ManufacturerStore } from "./pages/ManufacturerStore/ManufacturerStore";
import EnhancedSupplierSafetyScreen from "./enhanced/app_widgets/EnhancedSupplierSafetyScreen";
import { ChatButton } from "./components/ChatButton/ChatButton";
import { PharmacyHome } from "./pages/PharmacyHome/PharmacyHome";
import { InvoiceHistory } from "./pages/InvoiceHistory/InvoiceHistory";
import { Home } from "./pages/Home";
import { RefillOpportunitiesPage } from "./pages/RefillOpportunitiesPage";

function App() {
  useIdentifyUsers();

  const { start } = useContext(TimerContext);
  useEffect(() => {
    start(0.5 * 60 * 60); // 30min
  }, []);

  console.log("Rendering App");

  return (
    <div tw="min-h-screen print:h-unmodified">
      <Router>
        <ReloadPageAlert />
        <PageAlerts />

        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/supplierNavigation">
            <EnhancedSupplierNavBar />
          </Route>

          <Route exact path="/supplierBrowserBar">
            <EnhancedSupplierBrowserBar />
          </Route>

          <Route exact path="/supplierSafetyScreen">
            <EnhancedSupplierSafetyScreen />
          </Route>

          <Route exact path="/demo" component={DemoMode} />

          <Route exact path="/invoice-history" component={InvoiceHistory} />

          <RoleProtectedRoute
            path="/manufacturer-store/:supplierId"
            role={UserBusinessRoles.BUYING}
            component={ManufacturerStore}
          />

          <RoleProtectedRoute
            path="/(search|selectDrug|compare|supplierAccounts|refill-opportunities)"
            role={UserBusinessRoles.BUYING}
          >
            <NavBar />
            <EnhancedScreenLockInactivity>
              <Switch>
                <Route
                  path="/supplierAccounts"
                  component={EnhancedSupplierAccount}
                />
                <Route path="/search" component={PharmacyHome} />
                <Route path="/selectDrug" component={SelectDrug} />
                <Route
                  path="/refill-opportunities"
                  component={RefillOpportunitiesPage}
                />
                <Route path="/compare" component={Compare} />
              </Switch>
            </EnhancedScreenLockInactivity>
          </RoleProtectedRoute>

          {/* Shopping flow */}
          <RoleProtectedRoute
            path="/(previousShoppingLists|shopping-list-add-rx|shoppingList|shoppingListTwo|ShoppingListThree|shoppingListPrint|testShoppingListEnhance)"
            role={UserBusinessRoles.BUYING}
          >
            <EnhancedScreenLockInactivity>
              <Switch>
                <Route
                  path="/previousShoppingLists"
                  component={PreviousShoppingLists}
                />
                <Route path="/shoppingList" component={ShoppingList} />
                <Route path="/shopping-list-add-rx" component={ShoppingRx} />
                <Route path="/shoppingListTwo" component={ShoppingListTwo} />
                <Route
                  path="/ShoppingListThree"
                  component={ShoppingListThree}
                />
                <Route
                  path="/shoppingListPrint"
                  component={ShoppingListPrint}
                />
                <Route path="/testShoppingListEnhance">
                  <EnhancedShoppingListSearchTestRoute />
                </Route>
              </Switch>
            </EnhancedScreenLockInactivity>
          </RoleProtectedRoute>

          <RoleProtectedRoute
            path="/performance"
            role={UserBusinessRoles.REPORTING}
            component={Performance}
          />

          <RoleProtectedRoute
            path="/manufacturer"
            role={UserBusinessRoles.MANUFACTURING}
            component={Manufacturer}
          />

          <Route path="/unauthorized" component={UnauthorizedPage} />

          <Route path="/admin" component={Admin} />
        </Switch>

        <PharmacyLoader />
        <ChatButton />
        <ScrollToTop />
      </Router>
    </div>
  );
}

function useIdentifyUsers() {
  const { user } = useContext(AppContext);
  const { enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const { currentBuyingPharmacy } = useBuyingPharmacy();

  const userNameWithPharmacy = useMemo(() => {
    if (!user) return;
    if (!currentBuyingPharmacy) return user.full_name;
    return `${user.full_name} - ${currentBuyingPharmacy.name}`;
  }, [user, currentBuyingPharmacy]);

  // Sentry
  useEffect(() => {
    if (!user) return;

    Sentry.setUser({
      id: user.id.toString(),
      email: user.email,
      username: userNameWithPharmacy,
    });
  }, [user, userNameWithPharmacy]);

  // Chatlio
  useEffect(() => {
    // The global chatlio variable is an empty array until the chatlio script is loaded
    if (
      Array.isArray(window._chatlio) ||
      !(window._chatlio.vsUuid && window._chatlio.vsUuid())
    ) {
      document.addEventListener("chatlio.ready", identifyChatlioUser);

      return () => {
        document.removeEventListener("chatlio.ready", identifyChatlioUser);
      };
    } else {
      identifyChatlioUser();
      return () => {};
    }

    function identifyChatlioUser() {
      window._chatlio.hide();
      if (user) {
        window._chatlio.identify(user.id.toString(), {
          name: user.full_name,
          email: user.email,
          version: enhancedClientVersion || "Web",
        });
      } else {
        window._chatlio.reset();
      }
    }
  }, [user]);
}

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

export default withAuthenticationRequired(App);
