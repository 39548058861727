import React, { useCallback, useState } from "react";
import { styled } from "twin.macro";
import { useHistory } from "react-router-dom";
import { WideContainer } from "../../../components/containers/WideContainer";
import { Button } from "../../../components/rxLibrary/buttons";
import { Title } from "../../../components/rxLibrary/typography/Title";
import { useShoppingCartServerUpdater } from "../../../contexts/ShoppingCartServerContext";
import { useStorageState } from "../../../contexts/StorageContext";
import { WHITE_1 } from "../../../components/rxLibrary/colors";

const Wrapper = styled.div`
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.31);
  background-color: ${WHITE_1};
`;

export function ShoppingBar({
  loading,
  isInvalidQty,
  setSupplierModalActive,
}: {
  loading: boolean;
  isInvalidQty: boolean;
  setSupplierModalActive: (v: boolean) => void;
}) {
  const history = useHistory();
  const { pushBlob } = useShoppingCartServerUpdater();
  const { waitButtonMode } = useStorageState();
  const [isCancelling, setIsCancelling] = useState(false);

  const openSupplierModal = useCallback(() => {
    setSupplierModalActive(true);
  }, [setSupplierModalActive]);

  const handleCancel = useCallback(async () => {
    setIsCancelling(true);

    await pushBlob({
      force: true,
      cb: () => {
        history.push("/shoppingList");
      },
    });
  }, [pushBlob]);

  return (
    <Wrapper>
      <WideContainer tw="flex justify-between items-center relative h-[96px]">
        <Title>Shopping List - Select Quantities</Title>

        <div tw="flex items-center space-x-2">
          <Button variant="text-2" onClick={handleCancel}>
            {isCancelling ? "Going back..." : "Back"}
          </Button>

          {!loading && (
            <Button
              disabled={isInvalidQty || waitButtonMode}
              onClick={openSupplierModal}
            >
              {isInvalidQty ? "Increase Quantities" : "See Suppliers & Pricing"}
            </Button>
          )}
        </div>
      </WideContainer>
    </Wrapper>
  );
}
