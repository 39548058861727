import { useCallback, useEffect, useMemo, useState } from "react";
import "twin.macro";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import type { Supplier } from "../../../../../utilities/types";
import type { ShippingInfo } from "../../../../../utilities/shipping/shipping.constants";
import type {
  ManufacturerStoreItem,
  ManufacturerStoreAddToCart,
  ManufacturerStoreRemoveFromCart,
  ManufacturerStoreUpdateItemQuantity,
  ManufacturerStoreItemsByType,
} from "../Catalog.constants";
import { findAndRemoveFromArray } from "../../../../../utilities/arrays/findAndRemoveFromArray";
import { insertArray } from "../../../../../utilities/arrays/insertArray";
import { postOrder } from "../../../../../services/order";
import { getShippingInfo } from "../../../../../utilities/shipping/shippingInfo";
import { isC2Drug } from "../../../../../utilities/drugInfo/isC2Drug";
import { CatalogDrug_All } from "../../../../../services/types";
import { useRequestClient } from "../../../../../services/request/requestClient";
import { getQueryParamsFeaturedItems } from "./getQueryParamsFeaturedItems";
import { useLocalStorageCartCatalog } from "./useLocalStorageCartCatalog";
import { useCatalogItems } from "./useCatalogItems";
import { useHasFoamItems } from "./useHasFoamItems";

export type ManufacturerStoreSubmitOrder = ReturnType<
  typeof useManufacturerStoreItems
>["submitOrder"];

// TODO ManufacturerStoreItems should be in denormalized form natively
function convertToDrugInfoV2_Denormalized(
  item: ManufacturerStoreItem
): CatalogDrug_All {
  return {
    ...item,
    drug: { id: item.drug.id },
    catalogInfo: { id: item.catalogInfo.id },
    referenceData: {
      drugInfo: item.drug,
      catalogInfo: item.catalogInfo,
    },
  };
}

export function useManufacturerStoreItems({
  supplier,
  pharmacyId,
}: {
  supplier: Supplier;
  pharmacyId: number;
}) {
  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>();
  const [shoppingListItems, setShoppingListItems] = useState<
    ManufacturerStoreItem[]
  >([]);
  const [itemsByType, setItemsByType] = useState<ManufacturerStoreItemsByType>({
    featuredC2Items: [],
    featuredItems: [],
    otherC2Items: [],
    otherItems: [],
  });
  const supplierId = supplier.id;

  const clientRequest = useRequestClient();
  const { catalogItemsData, error } =
    useCatalogItems({ supplierId, pharmacyId }) ?? {};

  const {
    items: catalogItems,
    topSellingItems,
    mostRecentPurchases,
  } = catalogItemsData ?? {};

  const {
    initialLocalStorageCartCatalog,
    updateLocalStorageCartCatalog,
    removeLocalStorageCartCatalog,
  } = useLocalStorageCartCatalog({ supplierId, pharmacyId });

  const cartItems = useMemo(() => {
    return shoppingListItems.filter((item) => item.status === "list");
  }, [shoppingListItems]);

  const hasFoamItems = useHasFoamItems(supplierId, cartItems);

  const updateItem = useCallback(
    (ndc: string, newPayload: Partial<ManufacturerStoreItem>) => {
      const [filteredCartItems, item, index] = findAndRemoveFromArray(
        shoppingListItems,
        (item) => {
          return item.ndc === ndc;
        }
      );
      if (!item) return;

      const newItem: ManufacturerStoreItem = {
        ...item,
        ...newPayload,
      };
      const newCartItems = insertArray(filteredCartItems, newItem, index);
      setShoppingListItems(newCartItems);
      updateLocalStorageCartCatalog(newCartItems);
    },
    [shoppingListItems]
  );

  const addToCart: ManufacturerStoreAddToCart = useCallback(
    (ndc: string) => {
      updateItem(ndc, { status: "list" });
    },
    [updateItem]
  );

  const removeFromCart: ManufacturerStoreRemoveFromCart = useCallback(
    (ndc: string) => {
      updateItem(ndc, { status: "add" });
    },
    [updateItem]
  );

  const updateItemQuantity: ManufacturerStoreUpdateItemQuantity = useCallback(
    (ndc: string, numPackages: number) => {
      if (numPackages === 0) {
        removeFromCart(ndc);
        return;
      }
      updateItem(ndc, { numPackages });
    },
    [updateItem, removeFromCart]
  );

  const submitOrder = useCallback(
    async ({ skipDuplicateCheck }: { skipDuplicateCheck: boolean }) => {
      const items = cartItems.map((item) => ({
        numPackages: item.numPackages,
        supplierItemNumber: item.catalogInfo.supplierItemNumber,
      }));

      const response = await postOrder(clientRequest, {
        retries: skipDuplicateCheck ? undefined : 3,
        order: { items, skipDuplicateCheck },
        supplierId,
        pharmacyId,
        shouldSkipSentryNotification: (error: any) => {
          if (skipDuplicateCheck) return false;
          // skip Sentry notification for 409 Conflict errors
          const shouldSkip = !!(error?.response?.status === 409);
          return shouldSkip;
        },
      });

      return response;
    },
    [cartItems, supplierId, pharmacyId, clientRequest]
  );

  useEffect(() => {
    if (!catalogItems?.length) return;

    const lsCartCatalogByNdc = keyBy(
      initialLocalStorageCartCatalog?.items ?? [],
      "ndc"
    );
    const queryParamsFeaturedItems = getQueryParamsFeaturedItems();
    const featuredItemsByNdc = keyBy(queryParamsFeaturedItems, "ndc");

    const cartItems = catalogItems.map<ManufacturerStoreItem>((item) => {
      const ndc = item.drug.ndc;
      const lsCartItem = lsCartCatalogByNdc[ndc];
      const featuredItem = featuredItemsByNdc[ndc];
      const featuredItemNumPackages = featuredItem?.numPackages;
      const bundleSize = item.catalogInfo?.directDeal?.bundleSize;
      const isC2 = isC2Drug(item.drug);
      const status = lsCartItem && !isC2 ? "list" : "add";

      let numPackages = 1;
      if (bundleSize) {
        numPackages = bundleSize;

        if (lsCartItem) {
          const lsCartItemNumPackages = lsCartItem.numPackages;

          if (
            !!lsCartItemNumPackages &&
            lsCartItemNumPackages % bundleSize === 0
          ) {
            numPackages = lsCartItemNumPackages;
          }
        }

        if (
          featuredItemNumPackages !== undefined &&
          featuredItemNumPackages % bundleSize === 0
        ) {
          numPackages = featuredItemNumPackages;
        }
      } else if (lsCartItem) {
        numPackages = lsCartItem.numPackages;
      }

      return {
        ...item,
        ndc,
        isC2,
        status,
        numPackages,
        featuredItem,
      };
    });

    const sortedCartItems = sortBy(cartItems, [
      (item) => item.drug.name.toLowerCase(),
      "ndc",
    ]);
    setShoppingListItems(sortedCartItems);
  }, [catalogItems]);

  useEffect(() => {
    const newShoppingList =
      shoppingListItems.reduce<ManufacturerStoreItemsByType>(
        (acc, item) => {
          const { isC2, featuredItem } = item;

          if (featuredItem) {
            if (isC2) acc.featuredC2Items.push(item);
            else acc.featuredItems.push(item);
          } else {
            if (isC2) acc.otherC2Items.push(item);
            else acc.otherItems.push(item);
          }

          return acc;
        },
        {
          featuredC2Items: [],
          featuredItems: [],
          otherC2Items: [],
          otherItems: [],
        }
      );

    setItemsByType(newShoppingList);
  }, [shoppingListItems]);

  useEffect(() => {
    const newShippingInfo = cartItems.length
      ? getShippingInfo(
          cartItems.map(convertToDrugInfoV2_Denormalized),
          supplier
        )
      : undefined;
    setShippingInfo(newShippingInfo);
  }, [cartItems, supplier]);

  return {
    cartItems,
    itemsByType,
    shippingInfo,
    hasFoamItems,
    topSellingItems,
    shoppingListItems,
    mostRecentPurchases,
    addToCart,
    submitOrder,
    removeFromCart,
    updateItemQuantity,
    removeLocalStorageCartCatalog,
    error,
  };
}
