import "twin.macro";
import { usePerformance } from "./usePerformance";
import { PerformanceReportType } from "./Performance.constants";
import { PerformanceReportModal } from "./PerformanceReportModal";
import { PerformanceReportChart } from "./PerformanceReportChart";
import { PerformanceReportError } from "./PerformanceReportError";

export function PerformanceReport() {
  const { toDate, fromDate, reportId, report, selectedPharmacies } = usePerformance();

  if (!report) return;

  if (report.reportType === PerformanceReportType.MODE) {
    return (
      <PerformanceReportModal
        startDate={fromDate}
        endDate={toDate}
        reportId={reportId}
        iframeProps={report.iframeProps}
        selectedPharmacies={selectedPharmacies}
        key={reportId}
      />
    );
  }

  if (
    report.reportType === PerformanceReportType.AG_CHART &&
    "Report" in report &&
    report.Report
  ) {
    return (
      <PerformanceReportChart
        reportId={reportId}
        startDate={fromDate}
        endDate={toDate}
        Report={report.Report}
        selectedPharmacies={selectedPharmacies}
        key={reportId}
      />
    );
  }

  return <PerformanceReportError />
}
