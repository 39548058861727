import { useContext } from "react";
import "twin.macro";
import { FullPageLoader } from "../loaders/FullPageLoader";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import { RefillOpportunitiesProvider } from "./RefillOpportunitiesContext";
import { RefillOpportunitiesReport } from "./RefillOpportunitiesReport/RefillOpportunitiesReport";

export function RefillOpportunities() {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);

  if (!currentBuyingPharmacy) return <FullPageLoader />;

  return (
    <RefillOpportunitiesProvider pharmacy={currentBuyingPharmacy}>
      <RefillOpportunitiesReport />
    </RefillOpportunitiesProvider>
  );
}
