import { ComponentProps, useCallback } from "react";
import "twin.macro";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { usePerformance } from "../usePerformance";

type onChangeFn = NonNullable<ComponentProps<typeof RangePicker>["onChange"]>;

const { RangePicker } = DatePicker;

export function PerformanceHeaderDateRangePicker() {
  const { reportId, fromDate, toDate, setToDate, setFromDate } = usePerformance();

  const onChange: onChangeFn = useCallback(
    (dates) => {
      if (dates === null) return;

      const [fromDate, toDate] = dates;
      if (fromDate) setFromDate(fromDate);
      if (toDate) setToDate(toDate);
    },
    [setToDate, setFromDate]
  );

  // Not show if the report is Pharmacy Payments
  if(reportId === 41) return <div style={{width: "276px"}}></div>;

  return (
    <div>
      <span tw="mr-2 font-light">Dates:</span>

      <RangePicker
        defaultValue={[fromDate, toDate]}
        style={{
          minHeight: "38px",
          borderRadius: "4px",
          borderColor: "hsl(0, 0%, 80%)",
        }}
        presets={[
          {
            label: "Last 30 Days",
            value: [dayjs().subtract(30, "day"), dayjs().subtract(1, "day")],
          },
          {
            label: "Last 7 Days",
            value: [dayjs().subtract(7, "day"), dayjs().subtract(1, "day")],
          },
        ]}
        onChange={onChange}
        allowClear={false}
      />
    </div>
  );
}
