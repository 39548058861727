import { useMemo, ComponentProps } from "react";
import tw from "twin.macro";

const LINK_STYLE = tw`underline`;

export function SimpleLink({
  openInNewTab,
  ...props
}: ComponentProps<"a"> & { openInNewTab?: boolean }) {
  const openInNewTabProps = useMemo(() => {
    return openInNewTab ? { target: "_blank", rel: "noopener noreferrer" } : {};
  }, [openInNewTab]);

  return <a css={LINK_STYLE} {...openInNewTabProps} {...props} />;
}

export function EmailLink({ email }: { email?: string }) {
  const href = useMemo(() => {
    return !!email && `mailto:${email}`;
  }, [email]);

  if (!href) return null;
  return (
    <a css={LINK_STYLE} href={href}>
      {email}
    </a>
  );
}
