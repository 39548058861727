import { useMemo } from "react";
import "twin.macro";
import { Row } from "react-table";
import "../../../scss/neededToday.scss";
import { Svg } from "../../../components/Svg";
import { formatNumberWithDecimals } from "../../../utilities/formatWithUnit";
import { uomDescription } from "../../../utilities/helpers";
import { PrescriptionQuantityItem, SVGType } from "../../../utilities/types";
import { IconDown } from "../../../components/rxLibrary/icons";
import { IconButton } from "../../../components/rxLibrary/buttons";
import { formatPurchaseByToDayjs } from "../../../utilities/dates/purchaseBy/formatPurchaseByToDayjs";
import { SHORT_DATE_FORMAT } from "../../../utilities/dates/dates.constants";

export function NumberOfUnitsCalendar({
  row,
  prescription,
}: {
  row: Row;
  prescription: PrescriptionQuantityItem;
}) {
  const { quantityWanted, drug } = prescription;
  const purchaseBy = prescription.rxList[0].purchaseBy;

  const [text1, text2] = useMemo(() => {
    const date = formatPurchaseByToDayjs(purchaseBy);
    if (date) return ["By", date.format(SHORT_DATE_FORMAT)];
    return ["TODAY"];
  }, [purchaseBy]);

  return (
    <div tw="flex items-center justify-between w-full">
      <Svg icon={SVGType.Calendar} text1={text1} text2={text2} />

      <div tw="flex-1">
        <p tw="text-center">
          {formatNumberWithDecimals(quantityWanted)}
          {uomDescription(0, drug, false, true)}
        </p>

        <IconButton tw="w-full">
          <IconDown {...row.getToggleRowExpandedProps()} />
        </IconButton>
      </div>
    </div>
  );
}
